/* Exporting an object with the keys 1, 2, 3, 4, 5. */
export default {
  1: {
    name: 'welcome',
    progress: 1,
  },
  2: {
    name: 'recommended',
    progress: 2,
  },
  3: {
    name: 'purchase',
    progress: 3,
  },
  4: {
    name: 'success',
    progress: 4,
  },
  5: {
    name: 'not-found',
    progress: 5,
  },
};
