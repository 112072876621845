import { useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';

const MenuDropdown = ({ title, children }) => {
  const [toggle, setToggle] = useState(false);

  const toggleMenu = () => {
    setToggle(!toggle);
  };

  return (
    <div className="">
      <button
        type="button"
        className="flex justify-between items-center w-full"
        onClick={toggleMenu}
      >
        <span className="text-lg text-gray-1 font-Karla">{title}</span>
        <span
          className={
            toggle
              ? 'rotate-180 text-primary-2 transition-all'
              : 'text-primary-2'
          }
        >
          <HiChevronDown />
        </span>
      </button>
      <div className="h-auto opacity-100 transition-opacity">
        <div
          aria-hidden={toggle ? 'true' : 'false'}
          className={toggle ? 'block' : 'hidden'}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default MenuDropdown;
