import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { PaystackButton } from 'react-paystack';
// import { Drawer, Button } from '../../components/common'; // uncomment to reactivate bank transfer
import { Drawer } from '../../components/common';
import PaymentProcessing from '../PaymentProcessing';
import PaymentConfirmation from '../Confirmation';
import PaymentError from '../PaymentError';
import getQuantity from '../../helpers/getTotalQuantity';
import { createOrderRequest } from '../../api/airtable/helpers';
// import { ReactComponent as BankIcon } from '../../../../assets/icons/bank.svg';
import { ReactComponent as DebitCard } from '../../../../assets/icons/debit-card.svg';
import PaymentGateways from '../../../../assets/img/payment-gateways.png';

const Payment = ({ paymentModal, setPaymentModal }) => {
  const paymentFocusRef = useRef();
  const [paymentResponseModal, setPaymentResponseModal] = useState(null);
  // const [transferLoading, setTransferLoading] = useState(false); // uncomment to activate bank transfer feature
  const [onlineResponseModal, setOnlineResponseModal] = useState(null);
  const [paystackResponse, setPaystackResponse] = useState({});
  const [referenceId, setReferenceId] = useState(''); // uncomment to activate bank transfer

  const PAYSTACK_PUBLIC_KEY = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;

  const { products, cartTotal, deliveryDetails } = useSelector(
    (product) => product?.shopFlow
  );

  const PaystackProps = {
    amount: Number(cartTotal) * 100,
    email: deliveryDetails.email,
    metadata: {
      ...deliveryDetails,
    },
    publicKey: PAYSTACK_PUBLIC_KEY,
    onSuccess: (data) => {
      const trxDate = moment(new Date(Date.now())).format(
        'DD MMM YYYY, h:mm a'
      );
      const trxDetails = { ...data, trxDate };
      const paymentData = {
        ...deliveryDetails,
        orderedProducts: products,
        paymentReference: data.trxref,
      };

      const createPayment = async () => {
        const { status } = await createOrderRequest(paymentData);

        if (status === 200) {
          setPaystackResponse(trxDetails);
          const paymentUrl = '/shop/payment-receipt';
          const paymentTitle =
            'SourceMyGadgets | One Platform, Everything Gadgets';
          const paymentState = { additionalInformation: 'Payment Receipt' };
          window.history.pushState(paymentState, paymentTitle, paymentUrl);
          setOnlineResponseModal(true);
          // dispatch(clearCart());
        }
      };
      createPayment();
    },
  };

  /** Handle Bank Transfer */
  // const handlePaymentResponse = async () => {
  //   setTransferLoading(true);
  //   const data = {
  //     ...deliveryDetails,
  //     orderedProducts: products,
  //   };
  //   const { status, id } = await createOrderRequest(data);
  //   if (status === 200) {
  //     setPaymentResponseModal(true);
  //     setReferenceId(id);
  //     // dispatch(clearCart());
  //   } else {
  //     setPaymentResponseModal(false);
  //   }
  //   setTransferLoading(false);
  // };

  const TOTAL_PRICE_OF_ITEMS_IN_CART = cartTotal;
  const TOTAL_NUMBER_OF_ITEMS_IN_CART = getQuantity(products);

  return (
    <>
      <Drawer
        title="Payment"
        openModal={paymentModal}
        setOpenModal={setPaymentModal}
        autoFocusRef={paymentFocusRef}
      >
        <div className="my-16 flex flex-col max-w-md mx-auto">
          <div className="flex flex-col px-6 pb-6 gap-2">
            <h3 className="font-bold text-xl tracking-tightest text-primary-10 font-Karla">
              Pay &#8358;
              {new Intl.NumberFormat('en-US').format(
                TOTAL_PRICE_OF_ITEMS_IN_CART
              )}{' '}
              for {TOTAL_NUMBER_OF_ITEMS_IN_CART}{' '}
              {TOTAL_NUMBER_OF_ITEMS_IN_CART > 1 ? 'items' : 'item'}
            </h3>
            {/* <div className="flex flex-col items-center justify-center w-full p-6 border border-gray-3 bg-gray-1 rounded-lg py-6 gap-2">
              <div className="flex items-center justify-center gap-1">
                <div className="inline-flex flex-none h-[19px] w-[19px] items-center justify-center">
                  <BankIcon />
                </div>
                <h5 className="text-base font-bold tracking-tightest text-primary-10">
                  To Pay via Bank Transfer
                </h5>
              </div>
              <h4>SourceMyGadget Technologies Limited</h4>
              <h4>0043015936 - Stanbic IBTC Bank</h4>
              <Button
                onClick={handlePaymentResponse}
                variant="primary"
                className="w-full"
                disabled={transferLoading}
              >
                I have transferred the money
              </Button>
            </div> */}
            <div className="flex w-full mt-4">
              <PaystackButton
                className="flex items-center justify-center w-full bg-gray-1 rounded-lg p-3 gap-1"
                {...PaystackProps}
              >
                <span className="inline-flex flex-none h-[19px] w-[19px] items-center justify-center">
                  <DebitCard />
                </span>
                <span className="text-lg font-bold font-Karla tracking-tightest text-primary-10">
                  Pay Online
                </span>
              </PaystackButton>
            </div>
            <div className="flex w-full mt-8">
              <img src={PaymentGateways} alt="payment gateways" />
            </div>
          </div>
        </div>
      </Drawer>
      <div>
        <PaymentProcessing
          setPaymentResponseModal={setPaymentResponseModal}
          response={paymentResponseModal}
          referenceId={referenceId}
          setReferenceId={setReferenceId}
        />
        <PaymentConfirmation
          setPaymentResponseModal={setOnlineResponseModal}
          response={onlineResponseModal}
          trxDetails={paystackResponse}
        />
        <PaymentError
          setPaymentResponseModal={setPaymentResponseModal}
          response={paymentResponseModal}
        />
      </div>
    </>
  );
};

export default Payment;
