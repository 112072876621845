import { generate } from 'referral-codes';
import api from '../../../../api/services/airtable/api';
import airtableConfig from './config';

/**
 * @param {*} options
 * @param {string} query
 * Options is an object with the following structure:
 * {
 *  pageSize: number,
 *  searchQuery: string,
 *  productId: string,
 *  filters: {
 *      type: string,
 *      brand: string,
 *      state: string,
 *      operatingSystem: string,
 *      ram: string,
 *      internalStorage: string
 *  },
 *  offset: string
 * }
 *
 * The product Id field is used to get a product for the single page view. All other fields in options are not required when using product Id to get a single product.
 *
 * Offset is used in pagination. On first request an offset is retured in the response data object. Save this offset in state and include in next request to load the next set of products (keep {pageSize constant in the subsequent requests})
 *
 *
 */
const fieldNames = {
  type: 'Type',
  brand: 'Brand',
  state: 'State',
  operatingSystem: 'Operating system',
  ram: 'RAM',
  internalStorage: 'Internal storage',
};

const generateFilterFormula = (filters) => {
  const filterKeys = Object.keys(filters);

  const filterFormula = filterKeys
    .map((k) => `{${fieldNames[k]}} = "${filters[k]}"`)
    .join(', ');

  return filterFormula;
};

export const generateQuery = (options, isBlackFriday = false) => {
  let query;
  let fieldIds;
  if (isBlackFriday) {
    fieldIds = airtableConfig.tables.blackFridayProducts.fieldIds;
  } else {
    fieldIds = airtableConfig.tables.products.fieldIds;
  }
  // const { fieldIds } = airtableConfig.tables.products;

  //   Gets fields to be included in the response. Should only include fields in the config file
  const fields = Object.keys(fieldIds)
    .map((field) => `fields%5B%5D=${fieldIds[field]}`)
    .join('&');

  // If query is to get details for a single product
  if (options.productId) {
    const filterByFormula = encodeURIComponent(
      `{uuid} = "${options.productId}"`
    );
    query = `/?filterByFormula=${filterByFormula}&${fields}`;
  } else {
    const optionKeys = Object.keys(options);
    const queryComponent = [];
    let filterFormula = '';
    optionKeys.forEach((key) => {
      if (key === 'pageSize') {
        queryComponent.push(`pageSize=${options[key]}`);
      }
      if (key === 'searchQuery' && options[key]) {
        // New search algorithm 28th October 2022
        let wordArr = [];
        const searchQueryComponent = [];
        if (options[key].includes(' ')) {
          wordArr = options[key].split(' ');
        } else {
          wordArr.push(options[key]);
        }

        wordArr.forEach((word) => {
          searchQueryComponent.push(
            `REGEX_MATCH(LOWER({Name}), "${word.toLowerCase()}")`
          );
        });
        // Old search algorithm
        // const searchQuery = `SEARCH("${options[
        //   key
        // ].toLowerCase()}", LOWER({Name})) > 0`;

        const searchQuery = `AND(${searchQueryComponent.join(', ')})`;

        filterFormula += `${filterFormula.trim() && ', '}OR(${searchQuery})`;
      }
      if (key === 'offset') {
        queryComponent.push(`offset=${options[key]}`);
      }
      if (key === 'filters') {
        filterFormula += `${
          filterFormula.trim() && ', '
        }AND(${generateFilterFormula(options[key])})`;
      }
    });

    query = `?${queryComponent.join('&')}&${fields}`;

    if (filterFormula.trim()) {
      query += `&filterByFormula=AND(${filterFormula.trim()}, {Images}, {Price}, {Availability} = "Available")`;
    }
  }
  return query;
};

/**
 *
 * @param {string} query Query string returned generatedQuery
 * @param {boolean} isBlackFriday Whether to refer to the black friday table or the normal table. Default is false
 * @returns
 */

export const getProducts = async (query, isBlackFriday = false) => {
  let productTable;
  let fieldIds;
  if (isBlackFriday) {
    productTable = airtableConfig.tables.blackFridayProducts.id;
    fieldIds = airtableConfig.tables.blackFridayProducts.fieldIds;
  } else {
    productTable = airtableConfig.tables.products.id;
    fieldIds = airtableConfig.tables.products.fieldIds;
  }
  const url = `${airtableConfig.base}/${productTable}${query}&returnFieldsByFieldId=true`;

  const response = await api.get(url);
  const products = response.data.records.map((record) => {
    const product = { ...fieldIds };
    product.id = record.id;
    Object.keys(fieldIds).forEach((fieldId) => {
      product[fieldId] = record.fields[fieldIds[fieldId]];
    });
    Object.keys(product).forEach((k) => {
      if (!product[k]) {
        delete product[k];
      }
    });

    return product;
  });

  return {
    count: products.length,
    products: products.length > 0 ? [...products] : [],
    offset: response.data.offset,
  };
};

const createSoldProduct = async (data) => {
  const soldProductsTable = airtableConfig.tables.soldProducts;
  const url = `${airtableConfig.base}/${soldProductsTable.id}`;
  const records = [];
  let soldProducts;

  data.orderedProducts.forEach((p) => {
    const fields = {};
    for (let i = 0; i < p.quantity; i++) {
      if (p.oldPrice) {
        fields[soldProductsTable.fieldIds.blackFridayProduct] = [p.id];
      } else {
        fields[soldProductsTable.fieldIds.product] = [p.id];
      }
      fields[soldProductsTable.fieldIds.price] = p.price + p.insuranceCost;
      fields[soldProductsTable.fieldIds.insurance] = p.insurance ? 'Yes' : 'No';
      records.push({
        fields,
      });
    }
  });

  await api
    .post(url, {
      records,
    })
    .then((response) => {
      soldProducts = response.data.records;
    });

  return soldProducts;
};

const createSale = async (data, orderRequest) => {
  const salesTable = airtableConfig.tables.sales;
  const url = `${airtableConfig.base}/${salesTable.id}`;

  const fields = {};

  fields[salesTable.fieldIds.orderRequest] = [orderRequest];
  fields[salesTable.fieldIds.soldProducts] = data.soldProducts.map((p) => p.id);
  if (data.orderType === 'Delivery') {
    fields[salesTable.fieldIds.delivery] = data.area.price;
  } else {
    fields[salesTable.fieldIds.delivery] = 0;
  }
  let totalCost = 0;
  data.orderedProducts.forEach((p) => {
    for (let i = 0; i < p.quantity; i++) {
      totalCost += p.price + p.insuranceCost;
    }
  });
  fields[salesTable.fieldIds.amountPaid] =
    totalCost + data.orderType === 'Delivery' ? data.area.price : 0;

  await api
    .post(url, {
      records: [{ fields }],
    })
    .then((response) => response.data.records[0]);
};

export const createOrderRequest = async (data) => {
  data.orderStatus = 'Payment received';
  data.paymentMade = 'Yes';
  data.paymentTime = new Date(Date.now()).toISOString();
  data.channel = 'Online Store';

  if (data.orderType === 'Delivery') {
    delete data.pickupDate;
  }

  if (data.orderType === 'Pickup') {
    data.area = null;
    data.address = '';
    delete data.deliveryDate;
  }

  const orderRequestsTable = airtableConfig.tables.orderRequests;
  const url = `${airtableConfig.base}/${orderRequestsTable.id}`;
  const returnedDataState = {};
  if (!data.paymentReference) {
    data.orderStatus = 'Paid';
    [data.paymentReference] = generate({
      length: 9,
      prefix: 'smgtx_',
    });
  }

  const fields = {};

  if (data.deliveryDate) {
    data.deliveryDate = new Date(data.deliveryDate).toDateString();
  }

  const dataKeys = Object.keys(data);

  dataKeys.forEach((k) => {
    if (k !== 'area') {
      if (k === 'orderedProducts') {
        const products = [];
        const blackFridayProducts = [];
        data.orderedProducts.forEach((product) => {
          if (product.oldPrice) {
            blackFridayProducts.push(product.id);
          } else {
            products.push(product.id);
          }
        });
        fields[orderRequestsTable.fieldIds.orderedProducts] = products;
        fields[orderRequestsTable.fieldIds.blackFridayProducts] =
          blackFridayProducts;
      } else {
        fields[orderRequestsTable.fieldIds[k]] = data[k];
      }
    }
  });

  await api
    .post(url, {
      records: [
        {
          fields,
        },
      ],
    })
    .then(async (res) => {
      const soldProducts = await createSoldProduct(data);
      data.soldProducts = soldProducts;

      data.sale = await createSale(data, res.data.records[0].id);
      returnedDataState.status = res.status;
      returnedDataState.id = res.data.records[0].id;
    })
    .catch((error) => {
      returnedDataState.error = error;
    });

  return { ...returnedDataState };
};
