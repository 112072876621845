import { useEffect, useRef, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { Drawer, Button } from '../../components/common';

const PaymentError = ({ response, setPaymentResponseModal }) => {
  const [isOpen, setIsOpen] = useState(false);
  const paymentResponseFocusRef = useRef();

  const { deliveryDetails } = useSelector((product) => product?.shopFlow);

  useEffect(() => {
    if (response === false) {
      setIsOpen(true);
    }
    if (response === true) {
      setIsOpen(false);
    }

    setTimeout(() => {
      setPaymentResponseModal(null);
    }, 100);
  }, [response]);

  return (
    <Drawer
      title="Payment Error"
      openModal={isOpen}
      setOpenModal={setIsOpen}
      autoFocusRef={paymentResponseFocusRef}
    >
      <div className="my-16 flex flex-col max-w-lg mx-auto">
        <div className="flex flex-col gap-4 w-full items-center justify-center">
          <button type="button" className="flex w-full justify-center">
            <span className="inline-flex h-12 w-12 rounded-[50%] items-center justify-center bg-[#FFF5F5] text-red-500 text-4xl">
              <IoClose />
            </span>
          </button>
          <h4 className="text-xl font-bold font-Karla text-center text-primary-8">
            Your payment could not be completed
          </h4>
          <p className="text-sm font-normal text-center text-[#0A2647]">
            Your Payment could not be completed due to an error
          </p>

          <div className="flex w-full justify-between gap-4 mt-4">
            <Button variant="secondary" className="w-full mt-4 text-primary-10">
              <a
                href={`https://wa.me/2349052821496?text=${encodeURIComponent(
                  `Hi, my name is ${deliveryDetails?.lastname} ${deliveryDetails?.firstname}, I need help completing an order.`
                )}`}
              >
                Contact Support
              </a>
            </Button>
            <Button
              onClick={() => setIsOpen(false)}
              variant="primary"
              className="w-full mt-4"
            >
              Retry
            </Button>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default PaymentError;
