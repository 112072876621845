import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivacyPolicy from './pages/privacyPolicy';
import BlackFridayPage from './pages/shop/pages/BlackFridayPage';

const HomePage = lazy(() => import('./pages/home/HomePage'));
const ContactPage = lazy(() => import('./pages/contact/ContactPage'));
const OrderPage = lazy(() => import('./pages/order/OrderPage'));
const Recommend = lazy(() => import('./pages/recommend/RecommendPage'));
const IndividualPage = lazy(() => import('./pages/individual/IndividualPage'));
const RecommendationEngine = lazy(() =>
  import('./pages/individual/recommendationEngine')
);

const Paylater = lazy(() => import('./pages/individual/paylater'));
const BusinessHome = lazy(() => import('./pages/business/ForBusinessPage'));
const BusinessBulk = lazy(() => import('./pages/business/bulk'));
const Repair = lazy(() => import('./pages/business/repair'));
const Tradein = lazy(() => import('./pages/business/tradein'));
const Insurance = lazy(() => import('./pages/business/insurance'));
const InsuranceProtection = lazy(() => import('./pages/individual/insurance'));
const RequestQuotePage = lazy(() =>
  import('./pages/requestQuote/RequestQuotePage')
);
const About = lazy(() => import('./pages/about/about'));

const MarketPlace = lazy(() => import('./pages/marketplace/MarketPlaceHome'));
const MarketRecommendation = lazy(() =>
  import('./pages/marketplace/views/recommended/recommended')
);
const CheckoutPage = lazy(() =>
  import('./pages/marketplace/views/checkout/checkout')
);
const PaymentSuccess = lazy(() =>
  import('./pages/marketplace/views/paymentSuccess/paymentSuccess')
);
const Purchase = lazy(() => import('./pages/marketplace/views/purchase/index'));
const ShopHome = lazy(() => import('./pages/shop/pages/HomePage'));
const ShopCart = lazy(() => import('./pages/shop/pages/Cart'));
const ShopDelivery = lazy(() => import('./pages/shop/pages/Delivery'));
const Payment = lazy(() => import('./pages/shop/pages/Payment'));
const PaymentError = lazy(() => import('./pages/shop/pages/PaymentError'));
const PaymentProcessing = lazy(() =>
  import('./pages/shop/pages/PaymentProcessing')
);
const PaymentConfirmation = lazy(() =>
  import('./pages/shop/pages/Confirmation')
);
const SingleProduct = lazy(() => import('./pages/shop/pages/SingleProduct'));

const Spinner = () => (
  <svg className="animate-spin h-5 w-5 mr-3 " viewBox="0 0 24 24">
    {/* <!-- ... --> */}
  </svg>
);

const App = () => (
  <BrowserRouter>
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/blackfriday" element={<BlackFridayPage />} />
        <Route path="/order" element={<OrderPage />} />
        <Route path="/recommend" element={<Recommend />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/individual" element={<IndividualPage />} />
        <Route path="/individual/pay-later" element={<Paylater />} />
        <Route path="/individual/insurance" element={<InsuranceProtection />} />
        <Route
          path="/individual/recommendation-engine"
          element={<RecommendationEngine />}
        />
        <Route path="/business" element={<BusinessHome />} />
        <Route path="/business/bulk" element={<BusinessBulk />} />
        <Route path="/business/repair" element={<Repair />} />
        <Route path="/business/trade-in" element={<Tradein />} />
        <Route path="/business/insurance" element={<Insurance />} />
        <Route path="/business/request-quote" element={<RequestQuotePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/market/home" element={<MarketPlace />} />
        <Route path="/market/recommended" element={<MarketRecommendation />} />
        <Route path="/market/checkout" element={<CheckoutPage />} />
        <Route path="/market/payment-success" element={<PaymentSuccess />} />
        <Route path="/market/purchase" element={<Purchase />} />

        <Route path="/shop" element={<ShopHome />} />
        <Route path="/shop/cart" element={<ShopCart />} />
        <Route path="/shop/cart/delivery" element={<ShopDelivery />} />
        <Route path="/shop/cart/payment" element={<Payment />} />
        <Route
          path="/shop/cart/payment-processing"
          element={<PaymentProcessing />}
        />
        <Route
          path="/shop/cart/payment/confirmation"
          element={<PaymentConfirmation />}
        />
        <Route path="/shop/cart/payment/error" element={<PaymentError />} />
        <Route path="/shop/products/:productSlug" element={<SingleProduct />} />
        <Route
          path="/blackfriday/products/:productSlug"
          element={<SingleProduct isBlackFriday />}
        />
      </Routes>
    </Suspense>
  </BrowserRouter>
);

export default App;
