import { useEffect, useContext } from 'react';
import { GoSearch } from 'react-icons/go';
import { FiLoader } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Button } from '../common';
import ProductCard from '../ProductCard';
import typeFilters from './typeFilters';
import FilterContext from '../../contexts/FilterContext';

const ProductList = ({ grid, isBlackFriday = false }) => {
  const {
    searchQueryText,
    showQueryText,
    typeFilter,
    fetchProducts,
    products,
    setProducts,
    loading,
    setLoading,
    count,
    setCount,
    offset,
    setOffset,
    disableLoadMore,
    setDisableLoadMore,
  } = useContext(FilterContext);

  useEffect(() => {
    // dispatch(setProductsLoading(true));
    setLoading(true);
    fetchProducts('', isBlackFriday).then((res) => {
      setDisableLoadMore(false);
      setProducts(res.products);
      setLoading(false);
      setOffset(res.offset);

      // If there is no offset i.e no more products matching the fetch criteria disable load more button
      if (!res.offset) {
        setDisableLoadMore(true);
      }
      setCount(res.count);
    });
  }, [typeFilter]);

  /**
   * It fetches products from the server, adds them to the existing products, and updates the offset
   * and count
   */
  const loadMoreProducts = async () => {
    const res = await fetchProducts(offset, isBlackFriday);
    // dispatch(setAllProducts(res.products));
    setProducts([...products, ...res.products]);
    if (res.offset !== undefined) {
      setOffset(res.offset);
    } else {
      setDisableLoadMore(true);
    }
    setCount(count + res.count);
  };

  return !loading ? (
    <div className="mx-4">
      {showQueryText.length > 0 && (
        <div className="m-8">
          <h1 className="text-[24px] text-primary-10 font-bold font-Karla">
            {products.length === 0 && 'No '} Search Results for &quot;
            {showQueryText}&quot;
          </h1>
          {products.length === 0 && (
            <p className=" text-[14px] font-Karla text-gray-4 mt-[8px] mb-[57px]">
              No Results.
            </p>
          )}
        </div>
      )}
      {products.length > 0 ? (
        <div>
          <div
            className={`grid gap-4 mt-4 grid-cols-${grid} sm:grid-cols-3 xl:grid-cols-4 xl:gap-12`}
          >
            {products.length > 0 &&
              products.map((product) => (
                <ProductCard
                  isBlackFriday={isBlackFriday}
                  key={product.id}
                  product={product}
                  grid={grid}
                />
              ))}
          </div>
          <div className="">
            <p className="text-center mt-[72px] text-gray-6">
              You have viewed {count} product(s).
            </p>
            <div className="text-center mt-6">
              <Button
                onClick={loadMoreProducts}
                variant="secondary"
                disabled={disableLoadMore}
              >
                Load More
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center pl-4 pr-4 mb-[3rem]">
          <GoSearch className="text-[50px] text-gray-4 mb-[51.7px]" />
          <div>
            <h3 className="text-center text-[18px] font-bold font-Karla text-primary-10 mb-[24px]">
              Nothing Found {searchQueryText ? `for "${searchQueryText}"` : ''}
              {typeFilter ? ` under "${typeFilters[typeFilter.type]}"` : '.'}
            </h3>
            <p className="text-center text-[14px] font-Beautiste text-primary-10 mb-[32px]">
              Check your search keyword and try again
            </p>
            <div className="flex flex-col sm:flex-row items-center justify-center text-center text-[18px] font-Beautiste text-primary-10">
              Can’t find something?{' '}
              <Link to="/order" className="sm:ml-[24px] ">
                {/* <Button
                  variant="inverse-primary"
                  className="text-[14px] font-Beautiste"
                >
                  Place a Custom Order
                </Button> */}
                <button
                  type="button"
                  className="rounded-lg py-2 px-4 disabled:cursor-not-allowed disabled:opacity-50 font-Beautiste text-[18px] bg-gray-1 text-primary-4 hover:bg-gray-2 "
                >
                  Place a Custom Order
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="h-[50vh] flex items-center justify-center">
      <div className="flex space-x-2 items-center">
        <span className="animate-spin text-3xl text-primary-2">
          <FiLoader />
        </span>
        {/* <div className="font-Beautiste font-semibold text-base text-primary-8">
          Please wait...
        </div> */}
      </div>
    </div>
  );
};

export default ProductList;
