import { useEffect } from 'react';
import Navbar from '../../components/common/TopNav/TopNav';
import highlight from './data';

const PrivacyPolicy = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Navbar />
      <div className="mt-8 mb-4 flex items-center justify-center">
        <h1 className="font-Karla text-2xl tracking-wide  text-center  sm:text-[4xl] text-primary-10 font-bold leading-[37px] ">
          {' '}
          {highlight.heading}
        </h1>
      </div>
      <section className="md:px-16 px-4">
        <div className="flex items-center justify-center ">
          <span className="font-Beautiste text-start ">{highlight.intro}</span>
        </div>
        <div className="pt-2">
          <h2 className="font-Beautiste font-bold"> {highlight.title} </h2>
        </div>
        <div className="md:px-16 px-8 py-2">
          <ul className="font-Beautiste tracking-wide   text-start flex gap-2 flex-col">
            {highlight.options.map((value) => (
              <li className="font-Beautiste" key={value.list}>
                {value.list}
              </li>
            ))}
          </ul>
        </div>
      </section>
      {/* CONSENT SECTION */}
      <section className="md:px-16 px-4 mt-4 ">
        <div className="flex pb-4 items-center justify-start  ">
          <span className="font-Beautiste font-bold">{highlight.consent}</span>
        </div>
        <div className="flex items-center justify-start px-4 ">
          <span className="font-Beautiste tracking-wide   text-start">
            {highlight.consentBody}
          </span>
        </div>
      </section>
      {/* PERSONAL SECTION */}
      <section className="md:px-16 px-4 pt-4">
        <div className="flex items-center justify-start ">
          <span className="font-Beautiste font-bold">{highlight.personal}</span>
        </div>
        <div className="md:px-16 px-8 py-2">
          <ul className="font-Beautiste tracking-wide   text-start flex gap-2 flex-col">
            {highlight.personalOptions.map((value) => (
              <li
                className="font-Beautiste tracking-wide  text-start"
                key={value.listPersonal}
              >
                {value.listPersonal}
              </li>
            ))}
          </ul>
        </div>
      </section>
      {/* WHAT WE DO WITH INFORMATION SECTION */}
      <section className="md:px-16 px-4 pt-4">
        <div className="flex items-center justify-start ">
          <span className="font-Beautiste font-bold">{highlight.whatWeDo}</span>
        </div>
        <div className="flex items-center justify-start px-8 py-2 ">
          <span className="font-Beautiste tracking-wide   text-start">
            {highlight.whatWeDoIntro}
          </span>
        </div>
        <div className="px-8 md:px-16">
          <ul className="font-Beautiste flex gap-2 flex-col">
            {highlight.whatWeDoOptions.map((value) => (
              <li
                className="font-Beautiste tracking-wide  text-start"
                key={value.listWhatWeDo}
              >
                {value.listWhatWeDo}
              </li>
            ))}
          </ul>
        </div>
      </section>
      {/* COOKIES SECTION */}
      <section className="md:px-16 px-4 pt-4">
        <div className="flex items-center justify-start ">
          <span className="font-Beautiste font-bold">{highlight.cookies}</span>
        </div>
        <div className="flex items-center mt-4 justify-start px-4 ">
          <span className="font-Beautiste tracking-wide   text-start">
            {highlight.cookiesBody}
          </span>
        </div>
      </section>
      {/* How we protect your personal information */}
      <section className="md:px-16 px-4 pt-4">
        <div className="flex items-center justify-start ">
          <span className="font-Beautiste font-bold">{highlight.protect}</span>
        </div>
        <div className="flex items-center mt-4 justify-start px-4">
          <span className="font-Beautiste tracking-wide  text-start">
            {highlight.protectBody}
          </span>
        </div>
      </section>
      {/* 7.	How we share your information within the SOURCEMYGADGETS and with other users */}
      <section className="md:px-16 px-4 pt-4">
        <div className="flex items-center justify-start ">
          <span className="font-Beautiste font-bold">{highlight.share}</span>
        </div>
        <div className="flex items-center mt-4 justify-start px-4 ">
          <span className="font-Beautiste tracking-wide   text-start">
            {highlight.shareIntro}
          </span>
        </div>
        <div className="px-8 md:px-16">
          <ul className="font-Beautiste tracking-wide   text-start flex gap-4 flex-col">
            {highlight.shareOptions.map((value) => (
              <li className="font-Beautiste" key={value.listShare}>
                {value.listShare}
              </li>
            ))}
          </ul>
        </div>
      </section>
      <section className="md:px-16 px-4 pt-4">
        <div className="flex items-center px-8 mb-2 justify-start">
          <span className="font-Beautiste">{highlight.shareBreak}</span>
        </div>
        <div className="px-8 md:px-16">
          <ul className="font-Beautiste tracking-wide   text-start flex gap-4 flex-col">
            {highlight.shareOptions2.map((value) => (
              <li className="font-Beautiste" key={value.listShare2}>
                {value.listShare2}
              </li>
            ))}
          </ul>
        </div>
      </section>
      {/* 	SECURITY SECTION */}
      <section className="md:px-16 px-4 pt-4">
        <div className="flex items-center justify-start ">
          <span className="font-Beautiste font-bold">{highlight.security}</span>
        </div>
        <div className="px-8 md:px-16 mt-4 text-justify">
          <ul className="font-Beautiste tracking-wide   text-start flex gap-4 flex-col">
            {highlight.securityOptions.map((value) => (
              <li className="font-Beautiste" key={value.listSecurity}>
                {value.listSecurity}
              </li>
            ))}
          </ul>
        </div>
      </section>
      {/* 	9.	Data Confidentiality Rights */}
      <section className="md:px-16 px-4 pt-4">
        <div className="flex items-center justify-start ">
          <span className="font-Beautiste font-bold">{highlight.data}</span>
        </div>
        <div className="flex items-center mt-4 justify-start px-8  ">
          <span className="font-Beautiste tracking-wide  text-start">
            {highlight.dataBody}
          </span>
        </div>
      </section>
      {/* 	10.	Links to Other Websites and Premises */}
      <section className="md:px-16 px-4 pt-4">
        <div className="flex items-center justify-start ">
          <span className="font-Beautiste font-bold">{highlight.links}</span>
        </div>
        <div className="px-8 md:px-16 tracking-wide  mt-4 text-start">
          <ul className="font-Beautiste tracking-wide   text-start flex gap-4 flex-col">
            {highlight.linksOptions.map((value) => (
              <li className="font-Beautiste" key={value.listLinks}>
                {value.listLinks}
              </li>
            ))}
          </ul>
        </div>
      </section>
      {/* 	11.	Infringements and Remedies */}
      <section className="md:px-16 px-4 pt-4">
        <div className="flex items-center justify-start ">
          <span className="font-Beautiste font-bold">{highlight.remedies}</span>
        </div>
        <div className="flex items-center justify-start mt-4 px-8 ">
          <span className="font-Beautiste tracking-wide   text-start">
            {highlight.remediesBody}
          </span>
        </div>
      </section>
      {/* 	12.	Governing Law */}
      <section className="md:px-16 px-4 pb-8 pt-4">
        <div className="flex items-center justify-start  ">
          <span className="font-Beautiste font-bold">{highlight.law}</span>
        </div>
        <div className="flex items-center mt-4 justify-start px-8  ">
          <span className="font-Beautiste tracking-wide text-start">
            {highlight.lawBody}
          </span>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
