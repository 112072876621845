const airtableConfig = {
  base: 'appQN9gj9Ne391A1N',
  tables: {
    products: {
      id: 'tblgExYG3ZGZx2gDs',
      fieldIds: {
        // Id: 'fldCFpOW1NUfozgOY',
        // uuId: 'fldetfVwYgG5G1uad',
        name: 'fldYKbrYseaEjiYLl',
        type: 'fldu0ZstLypOjRyns',
        subType: 'fld0dxPSusVLvWbT9',
        model: 'fldFlW0m5JwaiNRws',
        ram: 'fldHVwmwdKMMpLu7h',
        internalStorage: 'fldyo6D3c5GKyBCgh',
        frontCamera: 'fldhMy6NLMUpLlLpb',
        rearCamera: 'fld535CWGgXs4Bmfi',
        brand: 'fldOs8Ate6r6JxYfW',
        series: 'fldoRf24aI0Kxnz0v',
        state: 'fldJRnSWGWDB4UpbW',
        price: 'fldg62VpsMy1Vc1t1',
        colors: 'fldV6KNNZYjNQcbgE',
        operatingSystem: 'fldzkiBMWp27jX0dq',
        softwareVersion: 'fld3EkRjdERyKkp8R',
        camera: 'fldUqSLZVztXEX8HW',
        simType: 'fldZMjwuuvVEnKBTI',
        images: 'fldovV5I4XoKbxdkV',
        battery: 'fldGEAxeUtZAqY2Fq',
        screenResolution: 'fldiygTrSSNZmGKb6',
        processor: 'fldruyG4moDID8XFh',
        gpu: 'fldTsQpVG5HZgu9qi',
        screenSize: 'fld66QheZSZg3eq9e',
        touchScreen: 'fldbaGatz6bHCc9tn',
        backlitKeyboard: 'fldUmB3eDrHdu6r71',
        screenType: 'fldv6SGo3pPTr37kg',
        externalPorts: 'fldADrOVkI6Lu8H4l',
        curvedScreen: 'fldG4kW9xi9CzDdvj',
      },
    },
    blackFridayProducts: {
      id: 'tblOJPd7yvzOmL8ZV',
      fieldIds: {
        // Id: 'fldCFpOW1NUfozgOY',
        // uuId: 'fldetfVwYgG5G1uad',
        name: 'fldwPtGpXK3t81Q7O',
        type: 'fld25hHUg4iD8AqJV',
        subType: 'fldyiP4jZYOAkF3fC',
        model: 'flddqefNAfpZ7wJSV',
        ram: 'fldf0OBXIgFBeumtK',
        internalStorage: 'fld6toSuHBzznkuCK',
        frontCamera: 'fldPRQlegiNeA4DLE',
        rearCamera: 'fldD8nRnbMQhTkeBL',
        brand: 'fldmxqPUJCkVygQBp',
        series: 'fldWWxhvFeTzm6rmY',
        state: 'fldhWF7nbswqTDhxp',
        price: 'fldObkaQXirQKVTPu',
        oldPrice: 'fld3M66AeOsJOf8lk',
        colors: 'fldtb22euucCFV3C7',
        operatingSystem: 'fld7pAQdrVVW8GSzT',
        softwareVersion: 'fldBJC6KIaKnz3huk',
        camera: 'fldsva0qq5mMtG03p',
        simType: 'fldxRBLVZ1Otcttfb',
        images: 'fldWAdk9zthz0g5Go',
        battery: 'fldeJSMFpZSpfHU1T',
        screenResolution: 'fldQDy8SnoGObpCxz',
        processor: 'fldZzQVvRUwxsRP1K',
        gpu: 'fldrx8EmbBAO5d1ML',
        screenSize: 'fldEb8wFuoS5SXivH',
        touchScreen: 'fldJfYpU4C4wrV1PQ',
        backlitKeyboard: 'fldsrTiF8XA2jPjtu',
        screenType: 'fld3baVPyVIIgMZGJ',
        externalPorts: 'fld8IJ3mPeZAjRzqO',
        curvedScreen: 'flde9CbA2O2rom5RM',
      },
    },
    orderRequests: {
      id: 'tbldoAvAsRCVCYYC1',
      fieldIds: {
        firstname: 'fldE9XTNgi5X6nnga',
        lastname: 'fldl8GTE3KTjgHZeS',
        email: 'fldvvm898YwQoLAHY',
        phone: 'fldnT5VLOsFxdqZSY',
        orderedProducts: 'fld7bZ3KSJ6XincOf',
        blackFridayProducts: 'fldhZSP1fWA36Fc1S',
        orderStatus: 'fldMikNw3TNhw1Gvb',
        address: 'fldmaeBkqVeysddyN',
        deliveryDate: 'fldSptBl7m9M8GOWF',
        paymentMade: 'fldeGRBNF1ms8CiFp',
        paymentTime: 'fldSlB9rzaZiyb83a',
        channel: 'fldyl1iDm2Hgqm4k5',
        paymentReference: 'fld5muMbxaDDRXk3P',
        orderType: 'fld6ekXypNv0RqiPC',
        pickupDate: 'fldJKLeDsya3TUkhv',
      },
    },
    sales: {
      id: 'tblDJbnHmkbLsmz3A',
      fieldIds: {
        orderRequest: 'fldbxejUgFqsaJF72',
        soldProducts: 'fldgw2wSIoHLcIU0i',
        delivery: 'fldSvy40l2loG1LX9',
        amountPaid: 'fldQCCsSNA1yvhO8y',
      },
    },
    soldProducts: {
      id: 'tblElna8hTszFwitY',
      fieldIds: {
        blackFridayProduct: 'fldzXm9ViEa9GFlOc',
        product: 'fld8VlWQZ7uHuBOcq',
        insurance: 'fldi3koT0VH7eJvDb',
        price: 'fld4pM2WpYsRg0CDy',
      },
    },
  },
};

export default airtableConfig;
