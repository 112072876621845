import * as Yup from 'yup';

const DeliverySchema = {
  withDelivery: Yup.object().shape({
    firstname: Yup.string().required('First Name is required'),
    lastname: Yup.string().required('Last Name is required'),
    email: Yup.string()
      .email('Please enter a valid email.')
      .trim()
      .required('Email is required.'),
    phone: Yup.string().required('Phone Number is required'),
    orderType: Yup.string(),
    area: Yup.mixed().required('Delivery Area is required.'),
    address: Yup.string().required('Delivery Address is required'),
    deliveryDate: Yup.string().required('Date is required').nullable(),
  }),

  withPickup: Yup.object().shape({
    firstname: Yup.string().required('First Name is required'),
    lastname: Yup.string().required('Last Name is required'),
    email: Yup.string()
      .email('Please enter a valid email.')
      .trim()
      .required('Email is required.'),
    phone: Yup.string().required('Phone Number is required'),
    orderType: Yup.string(),
    pickupDate: Yup.string().required('Pickup Date is required').nullable(),
  }),
};

export default DeliverySchema;
