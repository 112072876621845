import bannerSm1 from '../../../../../assets/blackFriday/bannerSm1.jpg';
import bannerSm2 from '../../../../../assets/blackFriday/bannerSm2.jpg';
import bannerSm3 from '../../../../../assets/blackFriday/bannerSm3.jpg';
import bannerSm4 from '../../../../../assets/blackFriday/bannerSm4.jpg';
import bannerSm5 from '../../../../../assets/blackFriday/bannerSm5.jpg';

const smPhotos = [
  {
    image: bannerSm1,
  },
  {
    image: bannerSm2,
  },
  {
    image: bannerSm3,
  },
  {
    image: bannerSm4,
  },
  {
    image: bannerSm5,
  },
];

export default smPhotos;
