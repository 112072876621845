import { createSlice } from '@reduxjs/toolkit';
import flowViews from '../../pages/order/formSettings/flowViews';

const initialState = {
  view: flowViews[1],
  items: [],
  isLoading: false,
  error: null,
};

export const orderFlowSlice = createSlice({
  name: 'orderFlow',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    loadingFailed: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    goToNextStep: (state, action) => {
      let canGoToNext = true;
      const formValues = action.payload;
      /* loop over form values and don't allow movement to next form if required
       values were not submitted */
      Object.keys(formValues).forEach((field) => {
        if (
          state.view.fields[field]?.required === true &&
          formValues[field].trim() === ''
        ) {
          canGoToNext = false; // eslint-disable-line
        }
      });

      if (canGoToNext === true) {
        // if there is no next view
        if (state.view.progress + 1 > Object.keys(flowViews).length) {
          // do nothing
        } else {
          // get the progress value of the current view
          // use that to put a new view into the state
          state.view = flowViews[(state.view.progress += 1)];
        }
      }
    },
    goToPrevStep: (state) => {
      // if there is no next view
      if (state.view.progress - 1 === 0) {
        // do nothing
      } else {
        // get the progress value of the current view
        // use that to put a new view into the state
        state.view = flowViews[state.view.progress - 1];
      }
    },
    /* A reducer that is used to change the view. */
    goToView: (state, { payload }) => {
      state.isLoading = payload === 'submitError' ? false : state.isLoading;
      state.view = Object.values(flowViews).find(
        (item) => item.name === payload
      );
    },
    /* Adding a product to the list. */
    addProductToList: (state, { payload }) => {
      let canGoToNext = true;

      /* Checking if the required fields are filled. */
      Object.keys(payload).forEach((field) => {
        if (
          state.view.fields[field]?.required === true &&
          payload[field].trim() === ''
        ) {
          canGoToNext = false;
        }
      });
      if (canGoToNext === true) {
        state.items.push(payload);
        state.view = flowViews[(state.view.progress += 1)];
      }
    },

    /* A reducer that is used to remove an item from the list. */
    removeProductFromList: (state, { payload }) => {
      const newItemList = state.items.filter((el) => el.id !== payload);
      state.items = newItemList;
      state.error = false;
    },

    /* A reducer that is used to add the values to the state. */
    addToValues: (state) => {
      if (state.view.progress + 1 > Object.keys(flowViews).length) {
        // do nothing
      } else if (state.items.length <= 4) {
        state.view = flowViews[(state.view.progress += 1)];
      }
    },
    clearItems: (state) => {
      state.items = [];
    },
    clearReduxState: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  goToNextStep,
  goToPrevStep,
  startLoading,
  goToView,
  loadingFailed,
  addProductToList,
  removeProductFromList,
  addToValues,
  clearReduxState,
  clearItems,
} = orderFlowSlice.actions;

export default orderFlowSlice.reducer;
