import React, { useContext } from 'react';
import Slider from 'react-slick';
import {
  BsArrowRightShort,
  BsArrowLeftShort,
  BsFillPhoneFill,
  BsLaptopFill,
} from 'react-icons/bs';
import { MdMonitor } from 'react-icons/md';
import { GiConsoleController } from 'react-icons/gi';
import { FaHeadphonesAlt } from 'react-icons/fa';
import './slider.css';
import FilterContext from '../../contexts/FilterContext';

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button
      type="button"
      className={`${className} flex items-center justify-center text-white text-lg h-8 w-8 rounded-[50%] hover:bg-primary-7 hover:text-white bg-primary-7 shadow-[0px_1px_16px_rgba(0,28,61,0.04),_0px_0px_2px_rgba(10,38,71,0.05)]`}
      style={{ ...style }}
      onClick={onClick}
    >
      <BsArrowRightShort className="inline-block" />
    </button>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button
      type="button"
      className={`${className} z-10 flex items-center justify-center text-white text-lg h-8 w-8 rounded-[50%] hover:bg-primary-7 hover:text-white bg-primary-7 shadow-[0px_1px_16px_rgba(0,28,61,0.04),_0px_0px_2px_rgba(10,38,71,0.05)]`}
      style={{ ...style }}
      onClick={onClick}
    >
      <BsArrowLeftShort className="inline-block" />
    </button>
  );
};

const Categories = () => {
  const { typeFilter, setTypeFilter } = useContext(FilterContext);

  const filterClickHandler = (e) => {
    const { filter } = e.target.dataset;
    if (typeFilter && filter === typeFilter?.type) {
      setTypeFilter(null);
      return;
    }
    setTypeFilter({ type: filter });
  };

  const settings = {
    dots: false,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: 'center relative',
    infinite: true,
    centerMode: false,
    swipeToSlide: true,
    initialSlide: 1,
    variableWidth: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          variableWidth: true,
        },
      },
    ],
  };

  return (
    <div className="">
      <Slider {...settings}>
        <div className="px-2">
          <button
            type="button"
            data-filter="Phones"
            className={`flex flex-none gap-4 items-center [border:1px_solid_rgba(50,79,115)] text-sm font-normal font-Karla ${
              typeFilter?.type !== 'Phones'
                ? 'text-primary-1 bg-primary-8'
                : 'text-primary-9 bg-primary-1'
            } py-2 px-3 rounded-[8px] shadow-[0px_1px_16px_rgba(0,28,61,0.04),_0px_0px_2px_rgba(10,38,71,0.05)]`}
            onClick={filterClickHandler}
          >
            <span>
              <BsFillPhoneFill className="text-lg" />
            </span>
            Mobile Phones
          </button>
        </div>
        <div className="px-2">
          <button
            type="button"
            data-filter="Laptops"
            className={`flex flex-none gap-2 items-center [border:1px_solid_rgba(50,79,115)] text-sm font-normal ${
              typeFilter?.type !== 'Laptops'
                ? 'text-primary-1 bg-primary-8'
                : 'text-primary-9 bg-primary-1'
            } font-Karla py-2 px-3 rounded-[8px] shadow-[0px_1px_16px_rgba(0,28,61,0.04),_0px_0px_2px_rgba(10,38,71,0.05)]`}
            onClick={filterClickHandler}
          >
            <span>
              <BsLaptopFill className="text-lg" />
            </span>
            Laptops and Desktops
          </button>
        </div>
        <div className="px-2">
          <button
            type="button"
            data-filter="Monitors"
            className={`flex flex-none gap-2 items-center [border:1px_solid_rgba(50,79,115)] text-sm font-normal ${
              typeFilter?.type !== 'Monitors'
                ? 'text-primary-1 bg-primary-8'
                : 'text-primary-9 bg-primary-1'
            } font-Karla py-2 px-3 rounded-[8px] shadow-[0px_1px_16px_rgba(0,28,61,0.04),_0px_0px_2px_rgba(10,38,71,0.05)]`}
            onClick={filterClickHandler}
          >
            <span>
              <MdMonitor className="text-lg" />
            </span>
            Monitors
          </button>
        </div>
        <div className="px-2">
          <button
            type="button"
            data-filter="Accessories"
            className={`flex flex-none gap-2 items-center [border:1px_solid_rgba(50,79,115)] text-sm font-normal ${
              typeFilter?.type !== 'Accessories'
                ? 'text-primary-1 bg-primary-8'
                : 'text-primary-9 bg-primary-1'
            } font-Karla py-2 px-3 rounded-[8px] shadow-[0px_1px_16px_rgba(0,28,61,0.04),_0px_0px_2px_rgba(10,38,71,0.05)]`}
            onClick={filterClickHandler}
          >
            <span>
              <FaHeadphonesAlt className="text-lg" />
            </span>
            Accessories
          </button>
        </div>

        <div className="px-2">
          <button
            type="button"
            data-filter="Gaming"
            className={`flex flex-none gap-2 items-center [border:1px_solid_rgba(50,79,115)] text-sm font-normal ${
              typeFilter?.type !== 'Gaming'
                ? 'text-primary-1 bg-primary-8'
                : 'text-primary-9 bg-primary-1'
            } font-Karla py-2 px-3 rounded-[8px] shadow-[0px_1px_16px_rgba(0,28,61,0.04),_0px_0px_2px_rgba(10,38,71,0.05)]`}
            onClick={filterClickHandler}
          >
            <span>
              <GiConsoleController className="text-lg" />
            </span>
            Gaming
          </button>
        </div>
      </Slider>
    </div>
  );
};

export default Categories;
