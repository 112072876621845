import React from 'react';
import { formatPrice } from '../../helpers';

const ProductItem = ({ item }) => {
  const INSURANCE_FEE = item.price * item.quantity * 0.05;
  const PRICE = item.price * item.quantity;

  return (
    <div>
      <div className="flex items-center justify-between gap-4">
        <p className="font-Beautiste font-normal text-gray-6 text-base">
          {item.name} x{item.quantity}
        </p>
        <h6 className="flex-none text-lg font-bold text-gray-6 font-Karla">
          &#8358;{formatPrice(PRICE.toFixed(2))}
        </h6>
      </div>
      {item.insurance === true ? (
        <div className="flex items-center justify-between gap-4">
          <p className="font-Beautiste font-normal text-gray-6 text-base">
            Insurance
          </p>

          <h6 className="flex-none text-lg font-bold text-gray-6 font-Karla">
            &#8358;{formatPrice(INSURANCE_FEE.toFixed(2))}
          </h6>
        </div>
      ) : null}
    </div>
  );
};
export default ProductItem;
