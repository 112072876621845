/* Exporting the object as the default export. */
export default {
  1: {
    name: 'gadgetInfo',
    title: 'Gadget Information',
    fields: {
      type: { required: true },
      brand: { required: false },
      prefMemorySize: { required: false },
      condition: { required: true },
      gadgetInsurance: { required: true },
    },
    progress: 1,
  },

  2: {
    name: 'budget',
    title: 'Budget',
    fields: {
      budget: { required: true },
      purpose: { required: true },
      features: { required: false },
    },
    progress: 2,
  },

  3: {
    name: 'personalInfo',
    title: 'Personal Information',
    fields: {
      firstName: { required: true },
      lastName: { required: true },
      email: { required: true },
      phone: { required: true },
    },
    progress: 3,
  },

  4: {
    name: 'purchaseInfo',
    title: 'Contact',
    fields: {
      prefPurchaseDate: { required: true },
      contactMethod: { required: true },
    },
    progress: 4,
  },

  5: {
    name: 'requestSent',
    title: 'Request sent',
    progress: 5,
  },

  6: {
    name: 'submitError',
    title: 'Error',
    progress: 6,
  },
};
