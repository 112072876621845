import { Link } from 'react-router-dom';
import { FaBoxOpen, FaMoneyBillWave } from 'react-icons/fa';
import { TiSpanner } from 'react-icons/ti';
import { BsShieldFill } from 'react-icons/bs';
import { RiExchangeFill } from 'react-icons/ri';
import { MdVerified } from 'react-icons/md';
import { HiChevronRight } from 'react-icons/hi';
import MenuDropdown from './MenuDropdown';

const MobileNavigation = ({ open }) => (
  <div
    className={`${
      open ? 'h-screen' : 'h-0 hidden'
    } flex flex-col transition-all w-full gap-8 py-8 px-4 bg-primary-10 fixed overflow-y-scroll z-50 lg:hidden`}
  >
    <MenuDropdown title="For Businesses">
      <div className="flex flex-col gap-y-8 py-8">
        <div className="flex gap-x-6 items-start">
          <div className="inline-flex flex-none h-12 w-12 text-accent-3 text-xl bg-white rounded-[50%] justify-center items-center ">
            <FaBoxOpen />
          </div>
          <div className="flex flex-col">
            <h3 className="text-lg text-gray-1 font-Karla font-bold">
              Quality Gadgets in Bulk
            </h3>
            <p className="text-sm text-gray-1 font-Beautiste">
              Best-suiting gadgets within your budget.
            </p>
          </div>
        </div>
        <div className="flex gap-x-6 items-start">
          <div className="inline-flex flex-none h-12 w-12 text-accent-3 text-xl bg-white rounded-[50%] justify-center items-center ">
            <TiSpanner />
          </div>
          <div className="flex flex-col">
            <h3 className="text-lg text-gray-1 font-Karla font-bold">
              Repair and Maintenance
            </h3>
            <p className="text-sm text-gray-1 font-Beautiste">
              Technical support for malfunction and recurring device health
              maintenance.
            </p>
          </div>
        </div>
        <div className="flex gap-x-6 items-start">
          <div className="inline-flex flex-none h-12 w-12 text-accent-3 text-xl bg-white rounded-[50%] justify-center items-center ">
            <RiExchangeFill />
          </div>
          <div className="flex flex-col">
            <h3 className="text-lg text-gray-1 font-Karla font-bold">
              Trade-In Solutions
            </h3>
            <p className="text-sm text-gray-1 font-Beautiste">
              Exchange devices for cash in singles or bulk.
            </p>
          </div>
        </div>
        <div className="flex gap-x-6 items-start">
          <div className="inline-flex flex-none h-12 w-12 text-accent-3 text-xl bg-white rounded-[50%] justify-center items-center ">
            <BsShieldFill />
          </div>
          <div className="flex flex-col">
            <h3 className="text-lg text-gray-1 font-Karla font-bold">
              Gadget Insurance
            </h3>
            <p className="text-sm text-gray-1 font-Beautiste">
              Protection against Theft, Malfunction or Damage.
            </p>
          </div>
        </div>
      </div>
    </MenuDropdown>
    <MenuDropdown title="For Individuals">
      <div className="flex flex-col gap-y-8 py-8">
        <div className="flex gap-x-6 items-start">
          <div className="inline-flex flex-none h-12 w-12 text-primary-4 text-xl bg-white rounded-[50%] justify-center items-center ">
            <MdVerified />
          </div>
          <div className="flex flex-col">
            <h3 className="text-lg text-gray-1 font-Karla font-bold">
              Recommendation Engine
            </h3>
            <p className="text-sm text-gray-1 font-Beautiste">
              Best-suiting gadgets within your budget.
            </p>
          </div>
        </div>
        <div className="flex gap-x-6 items-start">
          <div className="inline-flex flex-none h-12 w-12 text-accent-4 text-xl bg-white rounded-[50%] justify-center items-center ">
            <FaMoneyBillWave />
          </div>
          <div className="flex flex-col">
            <h3 className="text-lg text-gray-1 font-Karla font-bold">
              Buy Now, Pay later
            </h3>
            <p className="text-sm text-gray-1 font-Beautiste">
              Assist your purchase.
            </p>
          </div>
        </div>

        <div className="flex gap-x-6 items-start">
          <div className="inline-flex flex-none h-12 w-12 text-validation-1 text-xl bg-white rounded-[50%] justify-center items-center ">
            <BsShieldFill />
          </div>
          <div className="flex flex-col">
            <h3 className="text-lg text-gray-1 font-Karla font-bold">
              Gadget Insurance
            </h3>
            <p className="text-sm text-gray-1 font-Beautiste">
              Protection against Theft, Malfunction or Damage.
            </p>
          </div>
        </div>
      </div>
    </MenuDropdown>
    <div className="flex justify-between items-center">
      <Link to="/contact" className="text-lg text-gray-1 font-Karla">
        Contact
      </Link>
    </div>
    <div className="flex justify-between items-center">
      <Link to="/about" className="text-lg text-gray-1 font-Karla">
        About Us
      </Link>
    </div>
    <div className="flex justify-between items-center">
      <Link
        to="/recommend"
        className="bg-white w-full text-primary-4 p-4 rounded-lg flex justify-center gap-x-4 items-center sm:w-auto"
      >
        Get a Recommendation{' '}
        <span className="text-primary-2">
          <HiChevronRight />
        </span>
      </Link>
    </div>
    <div className="flex">
      <Link
        to="/order"
        className="bg-white w-full text-primary-4 p-4 rounded-lg flex justify-center gap-x-4 items-center sm:w-auto"
      >
        Place an Order{' '}
        <span className="text-primary-2">
          <HiChevronRight />
        </span>
      </Link>
    </div>
  </div>
);

export default MobileNavigation;
