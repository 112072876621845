import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const Drawer = ({
  openModal,
  setOpenModal,
  autoFocusRef,
  children,
  className,
  title,
}) => {
  const [modalOpen, setModalOpen] = useState(openModal);
  const rootElement = document.getElementById('headlessui-portal-root');

  const closeAllModal = () => {
    if (rootElement) {
      rootElement.remove();
    }
  };

  return (
    <Transition.Root
      show={openModal}
      as={Fragment}
      beforeEnter={() => setModalOpen(true)}
      afterLeave={() => setModalOpen(false)}
    >
      <Dialog
        as="div"
        id="modal"
        className="fixed z-10 inset-0 overflow-y-auto"
        static
        open={modalOpen}
        onClose={setOpenModal}
        initialFocus={autoFocusRef}
      >
        <div
          className="flex items-center justify-center min-h-screen px-4  text-center sm:block
         sm:p-0"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              onClick={closeAllModal}
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity cursor-pointer"
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:min:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0 translate-x-3/4"
            enterTo="opacity-100 translate-x-0"
            leave="ease-in-out duration-200"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-3/4"
          >
            <div
              className={`inline-block align-bottom bg-white h-screen fixed text-left overflow-x-hidden overflow-y-scroll shadow-xl transform transition-all w-full p-4  sm:align-middle sm:p-8 sm:w-1/2 ${className}`}
            >
              <div className="min-h-screen">
                <div className="flex justify-between items-center border-b border-[#E0E6ED] pb-2">
                  <h2 className="text-[32px] font-Karla font-bold text-primary-10">
                    {title}
                  </h2>
                  <button
                    type="button"
                    ref={autoFocusRef}
                    onClick={() => {
                      setOpenModal(false);
                      closeAllModal();
                    }}
                    className="inline-flex flex-none justify-center items-center h-[32px] w-[32px] rounded-[50%] bg-primary-1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 cursor-pointer text-primary-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="mb-20">{children}</div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Drawer;
