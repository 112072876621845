import { useEffect, useState } from 'react';

const totalPrice = (CART_LIST_ARRAY) => {
  const [priceValue, setPriceValue] = useState(0);

  useEffect(() => {
    setPriceValue(
      CART_LIST_ARRAY.map((product) => {
        const INSURANCE_FEE = product.price * product.quantity * 0.05;

        const PRICE_OF_A_PRODUCT_WHEN_INSURANCE_IS_APPLIED =
          product.quantity * product.price + INSURANCE_FEE;

        const PRICE_OF_A_PRODUCT_PRICE_WHEN_INSURANCE_IS_NOT_APPLIED =
          product.quantity * product.price;

        return product.insurance === true
          ? PRICE_OF_A_PRODUCT_WHEN_INSURANCE_IS_APPLIED
          : PRICE_OF_A_PRODUCT_PRICE_WHEN_INSURANCE_IS_NOT_APPLIED;
      }).reduce((totalSum, currentValue) => totalSum + currentValue, 0)
    );
  }, [priceValue, CART_LIST_ARRAY]);

  return priceValue.toFixed(0);
};

export default totalPrice;
