import { createSlice } from '@reduxjs/toolkit';
import flowViews from '../../pages/recommend/formSettings/flowViews';

export const recommendFlowSlice = createSlice({
  name: 'recommendFlow',
  initialState: {
    view: flowViews[1],
    items: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    loadingFailed: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    goToNextStep: (state, action) => {
      let canGoToNext = true;
      const formValues = action.payload;
      // loop over form values and don't allow movement to next form is required
      // values were not submitted
      Object.keys(formValues).forEach((field) => {
        if (
          state.view.fields[field]?.required === true &&
          formValues[field].trim() === ''
        ) {
          canGoToNext = false; // eslint-disable-line
        }
      });

      if (canGoToNext === true) {
        // if there is no next view
        if (state.view.progress + 1 > Object.keys(flowViews).length) {
          // do nothing
        } else {
          // get the progress value of the current view
          // use that to put a new view into the state
          state.view = flowViews[(state.view.progress += 1)];
        }
      }
    },
    goToPrevStep: (state) => {
      // if there is no next view
      if (state.view.progress - 1 === 0) {
        // do nothing
      } else {
        // get the progress value of the current view
        // use that to put a new view into the state
        state.view = flowViews[state.view.progress - 1];
      }
    },
    goToView: (state, { payload }) => {
      state.isLoading = payload === 'submitError' ? false : state.isLoading;
      state.view = Object.values(flowViews).find(
        (item) => item.name === payload
      );
    },
    addProductToList: (state, { payload }) => {
      let canGoToNext = true;
      state.isLoading = false;

      Object.keys(payload).forEach((field) => {
        if (
          state.view.fields[field]?.required === true &&
          payload[field].trim() === ''
        ) {
          canGoToNext = false;
        }
      });

      if (canGoToNext === true) {
        state.items.push(payload);
        state.view = flowViews[(state.view.progress += 1)];
      }
    },

    nextStepWithoutValues: (state) => {
      const canGoToNext = true;
      if (canGoToNext === true) {
        if (state.view.progress + 1 > Object.keys(flowViews).length) {
          // do nothing
        } else {
          state.view = flowViews[(state.view.progress += 1)];
        }
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  goToNextStep,
  goToPrevStep,
  startLoading,
  goToView,
  loadingFailed,
  addProductToList,
  nextStepWithoutValues,
} = recommendFlowSlice.actions;
export default recommendFlowSlice.reducer;
