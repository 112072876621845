/* eslint-disable react/button-has-type */

const Button = ({ type, children, className, variant, disabled, ...rest }) => {
  const variants = {
    secondary: 'bg-gray-1 text-primary-10 hover:bg-gray-2',
    'inverse-primary': 'bg-gray-1 text-primary-4 hover:bg-gray-2',
    primary: 'bg-primary-4 hover:bg-primary-5 text-white',
  };
  return (
    <button
      type={type}
      {...rest}
      disabled={disabled}
      className={`rounded-lg py-2 px-4 disabled:cursor-not-allowed disabled:opacity-50 font-Karla text-base sm:text-lg ${className} ${variants[variant]}`}
    >
      {children}
    </button>
  );
};

export default Button;
