export default {
  1: {
    name: 'serviceInfo',
    title: 'Service Information',
    fields: {
      serviceType: { required: true },
      extraDetails: { required: false },
    },
    progress: 1,
  },
  2: {
    name: 'companyInfo',
    title: 'Company Information',
    fields: {
      companyName: { required: true },
      contactFirstName: { required: true },
      contactLastName: { required: true },
      contactRole: { required: true },
      email: { required: true },
      phone: { required: true },
    },
    progress: 2,
  },
  3: {
    name: 'requestPlaced',
    title: 'Request Sent',
    progress: 3,
  },
  4: {
    name: 'submitError',
    title: 'Error',
    progress: 4,
  },
};
