import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  addItemToCart,
  // increaseQuantity,
} from '../../../../redux/shopFlow/addToCartSlice';
import { Button } from '../common';
import { formatPrice } from '../../helpers';
import { ReactComponent as CartAdd } from '../../../../assets/icons/cart-add.svg';
import convertNameToSlug from '../../helpers/convertNameToSlug';

const ProductCard = ({ product, grid, isBlackFriday }) => {
  // I am using the cartList to store the data from the addToCart Redux Store.
  // The cartList contains all the items added to the cart.
  const [cartList, setCartList] = useState();
  const [addedState, setAddedState] = useState(false);
  const dispatch = useDispatch();
  const cartState = useSelector((state) => state?.shopFlow);

  // This function dispatches the addItemToCart action, and the payload is the product prop
  const handleAddCart = (e, payload) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(addItemToCart(payload));
    setAddedState(true);
    setTimeout(() => {
      setAddedState(false);
    }, 2000);
  };

  useEffect(() => {
    setCartList(cartState);
  }, [cartState, cartList]);

  return (
    <Link
      className="bg-white flex flex-col items-start justify-end rounded-xl m-0 p-4 w-full transform transition duration-500 hover:shadow-lg hover:400 sm:w-full sm:m-2"
      // style={{ maxWidth: '400px' }}
      to={`/${
        isBlackFriday ? 'blackfriday' : 'shop'
      }/products/${convertNameToSlug(product.name, product.id)}`}
      aria-hidden
    >
      <div className="w-full flex justify-center">
        <img
          src={product.images ? product?.images[0].url : ''}
          alt={product?.name}
          className={grid === 2 ? 'h-full object-cover' : 'h-80 object-contain'}
        />
      </div>
      <h6 className="text-sm font-normal text-gray-6 font-Beautiste mt-6 flex flex-col">
        {product?.name}
      </h6>
      <div className="flex w-full gap-x-2 items-end">
        <h3
          className={`font-bold mt-1 text-${
            grid === 2 ? 'lg' : 'xl'
          } font-Karla text-primary-10 tracking-tightest flex flex-col`}
        >
          &#8358;{formatPrice(product.price)}
        </h3>
        {isBlackFriday ? (
          <h3
            className={`font-normal mt-1 text-${
              grid === 2 ? 'base' : 'lg'
            } font-Karla text-primary-10/50 tracking-tightest line-through flex flex-col`}
          >
            &#8358;{formatPrice(product.oldPrice)}
          </h3>
        ) : (
          ''
        )}
      </div>
      <div className="flex w-full gap-4 mt-6">
        {/* <Button variant="primary" className="w-full">
          Buy now
        </Button> */}
        <Button
          onClick={(e) => handleAddCart(e, product)}
          variant="primary"
          className={
            grid === 2
              ? 'tracking-tightest flex items-center w-full gap-1 justify-center text-[14px]'
              : 'tracking-tightest flex items-center w-full justify-center gap-4'
          }
        >
          <span className="inline-flex items-center">
            <CartAdd />
          </span>
          {!addedState ? (
            <span className="">Add to cart</span>
          ) : (
            <span className="">Added</span>
          )}
        </Button>
      </div>
    </Link>
  );
};

export default ProductCard;
