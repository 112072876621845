import moment from 'moment';
/**
 * It takes an id and an array of products, and returns the product with the matching id
 * @param id - the id of the product you want to find
 * @param productArr - an array of objects that represent products
 * @returns The product object that matches the id.
 */
export const findProductById = (id, productArr) => {
  const product = productArr.find((item) => item.id === id);
  return product;
};

/**
 * It takes in an id and an array of products, and returns the index of the product with the matching
 * id
 * @param id - the id of the product we want to find
 * @param productArr - an array of objects that contain product information
 * @returns The index of the product in the array.
 */
export const findProductIndex = (id, productArr) => {
  const productIndex = productArr.findIndex((product) => product.id === id);
  return productIndex;
};

/**
 * It takes a product price as an argument and returns the insurance cost of the product
 * @param productPrice - The price of the product
 * @returns the insurance value.
 */
export const formatInsurance = (productPrice) => {
  const insurance = Number((5 / 100) * productPrice);
  return insurance;
};

/**
 * If the current date is less than yesterday, return true.
 * @param currentDate - The current date selected in the calendar.
 * @param moment - The moment object that is passed to the component.
 */
export const restrictPreviousDate = (currentDate) =>
  currentDate && currentDate < moment().subtract(1, 'days');

/**
 * It takes a number and returns a string with the number formatted as a price
 * @param price - The price to format.
 * @returns The price is being formatted to a number with commas.
 */
export const formatPrice = (price) => {
  const formatted = new Intl.NumberFormat('en-US').format(price);
  return formatted;
};

/**
 * It takes in the product price, product discount, product insurance, delivery and total discount and
 * returns the total price
 * @param productPrice - The price of the product
 * @param productDiscount - The discount applied to the product.
 * @param productInsurance - The insurance price of the product
 * @param delivery - the delivery price
 * @param totalDiscount - This is the total discount that the user has applied to the cart.
 * @returns The total price of the product.
 */
export const getTotalprice = (
  productPrice,
  productDiscount,
  productInsurance,
  delivery,
  totalDiscount
) => {
  const totalPrice = Number(
    productPrice - productDiscount + productInsurance + delivery - totalDiscount
  );

  return totalPrice;
};

/**
 * It takes an array and a property name, and returns the sum of all the values of that property in the
 * array where {el.selected} denotes when a product is being selected
 * @param arr - an array of objects
 * @param prop - the property of the array elements that you want to sum up
 * @returns [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
 */
export const sumOfArrayProps = (arr, prop) => {
  let sum = 0;
  arr.forEach((el) => {
    if (el[prop] !== undefined && el.selected === true) {
      sum += el[prop];
    }
  });
  return sum;
};

/**
 * It takes in an object and a function, and then loops through the object's keys and passes each key
 * to the function
 * @param props - the props object that is passed to the form component
 * @param setFieldValue - This is a function that comes from the useFormik hook. It allows you to set
 * the value of a field.
 */
export const clearFormInput = (props, setFieldValue) => {
  Object.keys(props).forEach((field) => {
    setFieldValue(field, '');
  });
};
