import { createSlice } from '@reduxjs/toolkit';
import flowViews from '../../components/common/Footer/formSettings/flowViews';

export const subscriptionFlowSlice = createSlice({
  name: 'subscriptionFlow',
  initialState: {
    view: flowViews[1],
    isLoading: false,
    error: null,
  },
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    loadingFailed: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    goToNextStep: (state, action) => {
      let canGoToNext = true;
      const formValues = action.payload;

      // loop over form values and don't allow movement to next form is Required
      // values were not submitted
      Object.keys(formValues).forEach((field) => {
        if (
          state.view.fields[field]?.required === true &&
          formValues[field].trim() === ''
        ) {
          canGoToNext = false;
        }
      });
      if (canGoToNext === true) {
        // if there is no next view
        if (state.view.progress + 1 > Object.keys(flowViews).length) {
          // do nothing
        } else {
          // get the progress value of the current view
          // use that to put a new view into the state
          state.view = flowViews[(state.view.progress += 1)];
        }
      }
    },
    goToView: (state, { payload }) => {
      state.isLoading = payload === 'submitError' ? false : state.isLoading;
      state.view = Object.values(flowViews).find(
        (item) => item.name === payload
      );
    },
  },
});

export const { goToNextStep, goToView, startLoading, loadingFailed } =
  subscriptionFlowSlice.actions;

export default subscriptionFlowSlice.reducer;
