import Slider from 'react-slick';
import smPhotos from './smBanner';
import './pagebanner.css';

const PageBanner = () => {
  const settings = {
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,

    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <div className="w-[100%] h-auto friday-page">
      <Slider {...settings}>
        {smPhotos.map((photo) => (
          <img
            className="object-contain w-[100%] h-auto"
            src={photo.image}
            alt=""
          />
        ))}
      </Slider>
    </div>
  );
};

export default PageBanner;
