import banner1 from '../../../../../assets/blackFriday/banner1.jpg';
import banner2 from '../../../../../assets/blackFriday/banner2.jpg';
import banner3 from '../../../../../assets/blackFriday/banner3.jpg';
import banner4 from '../../../../../assets/blackFriday/banner4.jpg';
import banner5 from '../../../../../assets/blackFriday/banner5.jpg';
import banner6 from '../../../../../assets/blackFriday/banner6.jpg';

const photos = [
  {
    image: banner1,
  },
  {
    image: banner2,
  },
  {
    image: banner3,
  },
  {
    image: banner4,
  },
  {
    image: banner5,
  },
  {
    image: banner6,
  },
];

export default photos;
