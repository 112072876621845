import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import axios from 'axios';
import store, { persistor } from './redux/store';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const tagManagerArgs = {
  gtmId: 'GTM-K2XX7PR',
};

TagManager.initialize(tagManagerArgs);

axios({
  method: 'post',
  url: `https://graph.facebook.com/v14.0/${process.env.REACT_APP_FACEBOOK_PIXEL_ID}/events?access_token=${process.env.REACT_APP_FACEBOOK_CONVERSION_KEY}`,
  data: {
    data: [
      {
        event_name: 'Purchase',
        event_time: 1661336488,
        action_source: 'email',
        user_data: {
          em: [
            '7b17fb0bd173f625b58636fb796407c22b3d16fc78302d79f0fd30c2fc2fc068',
          ],
          ph: [null],
        },
        custom_data: {
          currency: 'USD',
          value: '142.52',
        },
      },
    ],
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
