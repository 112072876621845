export default {
  1: {
    name: 'gadgetInfo',
    title: 'Gadget Information',
    fields: {
      gadgetType: { required: true },
      gadgetBrand: { required: true },
      gadgetModel: { required: false },
      gadgetCondition: { required: true },
      gadgetInsurance: { required: true },
    },
    progress: 1,
  },

  2: {
    name: 'gadgetList',
    title: 'Gadget List',
    progress: 2,
  },

  3: {
    name: 'personalInfo',
    title: 'Personal Information',
    fields: {
      firstName: { required: true },
      lastName: { required: true },
      email: { required: true },
      phone: { required: true },
    },
    progress: 3,
  },

  4: {
    name: 'deliveryInfo',
    title: 'Delivery Information',
    fields: {
      prefDeliveryDate: { required: true },
      contactMethod: { required: true },
    },
    progress: 4,
  },

  5: {
    name: 'orderPlaced',
    title: 'Order placed',
    progress: 5,
  },

  6: {
    name: 'submitError',
    title: 'Error',
    progress: 6,
  },
};
