import { createSlice } from '@reduxjs/toolkit';

export const addProductToCart = createSlice({
  name: 'shopFlow',
  initialState: {
    products: [],
    filter: null,
    allProducts: [],
    cartTotal: 0,
    deliveryDetails: {},
    productsLoading: true,
  },
  reducers: {
    addItemToCart: (state, { payload }) => {
      // This function finds if the product has already been added to the cart. Returns true if
      // the product has been added and false if it has not.
      const product = state.products.find((item) => item.id === payload.id);
      if (product) {
        // If the product is already in the cart this code block is triggered
        state.products = state.products.map((item) => {
          if (item.id === payload.id) {
            return { ...item };
          }
          return item;
        });
        // If the product is not in the cart this code block is triggered
      } else {
        state.products = [
          ...state.products,
          { ...payload, quantity: 1, insurance: false, insuranceCost: 0 },
        ];
      }
    },
    // Removes an item from the cart by filtering out the item by the id from the payload
    removeFromCart: (state, { payload }) => {
      state.products = state.products.filter((item) => item.id !== payload);
    },

    increaseQuantity: (state, { payload }) => {
      state.products = state.products.map((item) => {
        if (item.id === payload) {
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      });
    },
    decreaseQuantity: (state, { payload }) => {
      state.products = state.products.map((item) => {
        if (item.id === payload) {
          if (item.quantity <= 1) {
            return { ...item, quantity: item.quantity };
          }
          return { ...item, quantity: item.quantity - 1 };
        }
        return item;
      });
    },
    toggleInsurance: (state, { payload }) => {
      state.products = state.products.map((item) => {
        if (item.id === payload) {
          return {
            ...item,
            insurance: !item.insurance,
            insuranceCost: !item.insurance ? item.price * 0.05 : 0,
          };
        }
        return item;
      });
    },
    setTypeFilter: (state, { payload }) => {
      state.typeFilter = payload;
    },
    setSearchQuery: (state, { payload }) => {
      state.searchQuery = payload;
    },
    // Adds an all products array to the state to prevent multiple requests to the server for the single product page
    setAllProducts: (state, { payload }) => {
      if (state.allProducts) {
        payload.forEach((p) => {
          if (!state.allProducts.find((item) => item.id === p.id)) {
            state.allProducts.push(p);
          }
        });
      } else {
        state.allProducts = [...payload];
      }
    },
    setCartTotal: (state, { payload }) => {
      state.cartTotal = payload;
    },
    setDeliveryDetails: (state, { payload }) => {
      state.deliveryDetails = payload;
    },

    setProductsLoading: (state, { payload }) => {
      state.productsLoading = payload;
    },

    clearCart: (state) => {
      state.products = [];
      state.cartTotal = 0;
    },
  },
});

export const {
  addItemToCart,
  increaseQuantity,
  removeFromCart,
  decreaseQuantity,
  toggleInsurance,
  setTypeFilter,
  setSearchQuery,
  setAllProducts,
  setDeliveryDetails,
  setCartTotal,
  clearCart,
  setProductsLoading,
} = addProductToCart.actions;

export default addProductToCart.reducer;
