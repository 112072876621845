import { combineReducers } from '@reduxjs/toolkit';

import orderFlowReducer from './orderFlow/orderFlowSlice';
import recommendFlowReducer from './recommendFlow/recommendFlowSlice';
import quoteRequestFlowReducer from './requestFlow/requestFlowSlice';
import subscriptionFlowReducer from './SubscriptionFlow/subscriptionFlowSlice';
import marketFlowReducer from './marketFlow/marketFlowSlice';
import addToCartReducer from './shopFlow/addToCartSlice';

const rootReducer = combineReducers({
  orderFlow: orderFlowReducer,
  recommendFlow: recommendFlowReducer,
  quoteRequestFlow: quoteRequestFlowReducer,
  subscriptionFlow: subscriptionFlowReducer,
  marketFlow: marketFlowReducer,
  shopFlow: addToCartReducer,
});

export default rootReducer;
