import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BsCheckCircleFill } from 'react-icons/bs';
import { Drawer, Button } from '../../components/common';
import { formatPrice } from '../../../../helpers';
import getQuantity from '../../helpers/getTotalQuantity';
import { clearCart } from '../../../../redux/shopFlow/addToCartSlice';
import { BLACKFRIDAY_PARAMS } from '../../components/BlackFriday';

const Confirmation = ({ response, setPaymentResponseModal, trxDetails }) => {
  const [isOpen, setIsOpen] = useState(false);
  const confirmationFocusRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { products, cartTotal, deliveryDetails } = useSelector(
    (state) => state.shopFlow
  );

  const rootElement = document.getElementById('headlessui-portal-root');
  const closeAllModal = () => {
    if (rootElement) {
      rootElement.remove();
    }
    setTimeout(() => {
      dispatch(clearCart());
    }, 2000);
  };

  const TOTAL_NUMBER_OF_ITEMS_IN_CART = getQuantity(products);
  const TOTAL_PRICE_OF_ITEMS_IN_CART = cartTotal;

  useEffect(() => {
    if (response === true) {
      setIsOpen(true);
    }

    if (response === false) {
      setIsOpen(false);
    }

    setTimeout(() => {
      setPaymentResponseModal(null);
    }, 100);
  }, [response]);

  return (
    <Drawer
      title="Confirmation"
      openModal={isOpen}
      setOpenModal={() => {
        setIsOpen(!isOpen);
        navigate('/shop');
      }}
      autoFocusRef={confirmationFocusRef}
    >
      <div className="my-8 flex flex-col max-w-md mx-auto">
        <div className="flex flex-col gap-4 w-full items-center justify-center">
          <div className="flex w-full justify-center">
            <span className="text-green-500 text-6xl">
              <BsCheckCircleFill />
            </span>
          </div>
          <h4 className="text-2xl font-bold font-Karla text-primary-8">
            Payment Complete
          </h4>
          <p className="text-sm font-normal text-center text-[#0A2647]">
            Your Payment has been completed. A representative will reach out to
            you with details about delivery in about 5 minutes.
          </p>
          <div className="border-y-4 w-full bg-white border-gray-1 border-dashed shadow-[0px_8px_64px_rgba(0,19,88,0.1)] rounded-lg p-4 mt-8">
            <h5 className="font-bold text-lg text-gray-6 font-Karla">
              Payment Reciept for {TOTAL_NUMBER_OF_ITEMS_IN_CART} Item(s)
            </h5>
            <h6 className="text-lg font-normal font-Karla text-gray-6">
              {trxDetails.trxDate}
            </h6>
            <div className="flex mt-4 justify-between">
              <h5 className="text-lg font-normal font-Karla text-gray-6">
                Payment Reference
              </h5>
              <h6 className="font-bold text-lg text-gray-6 font-Karla">
                {trxDetails.trxref}
              </h6>
            </div>
            <div className="mt-4">
              <h5 className="uppercase font-bold text-sm text-gray-4 font-Karla">
                Subtotal
              </h5>
              {products?.map((item) => (
                <div key={item.id} className="flex justify-between my-1 gap-2">
                  <h5 className="text-lg font-normal font-Karla text-gray-6 break-words whitespace-nowrap text-ellipsis overflow-hidden">
                    {item.name}
                  </h5>
                  <h6 className="font-bold text-lg text-gray-6 font-Karla">
                    &#8358;
                    {item.insurance === true
                      ? formatPrice(
                          item.price * (item.quantity ? item.quantity : 1) +
                            0.05 * item.price * item.quantity
                        )
                      : formatPrice(
                          item.price * (item.quantity ? item.quantity : 1)
                        )}
                  </h6>
                </div>
              ))}
            </div>
            <hr />
            {deliveryDetails.orderType === 'Delivery' ? (
              <div className="flex justify-between my-1">
                <h5 className="text-lg font-normal font-Karla text-gray-6 break-words">
                  Delivery
                </h5>
                <h6 className="font-bold text-lg text-gray-6 font-Karla">
                  {Number(TOTAL_PRICE_OF_ITEMS_IN_CART) > 10000 &&
                  BLACKFRIDAY_PARAMS.startDate < BLACKFRIDAY_PARAMS.today &&
                  BLACKFRIDAY_PARAMS.endDate > BLACKFRIDAY_PARAMS.today ? (
                    'Free'
                  ) : (
                    <>&#8358;{formatPrice(deliveryDetails?.area?.price)}</>
                  )}
                </h6>
              </div>
            ) : (
              ''
            )}
            <div className="flex flex-col items-end uppercase mt-4">
              <h5 className="font-bold text-sm font-Karla text-gray-4">
                Total
              </h5>
              <h3 className="font-bold font-Karla text-xl text-primary-10">
                &#8358;{formatPrice(TOTAL_PRICE_OF_ITEMS_IN_CART)}
              </h3>
            </div>
          </div>

          <Button
            variant="primary"
            onClick={() => {
              closeAllModal();
              navigate('/shop');
            }}
            className="w-full mt-4"
          >
            Close
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default Confirmation;
