export default {
  1: {
    name: 'userInfo',
    title: 'User Information',
    fields: {
      email: { required: true },
    },
    progress: 1,
  },
  2: {
    name: 'subscribed',
    title: 'Subscribed',
    progress: 2,
  },
};
