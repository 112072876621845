import { createSlice } from '@reduxjs/toolkit';
import {
  findProductById,
  findProductIndex,
  formatInsurance,
} from '../../helpers';
import flowViews from '../../pages/marketplace/settings/flowViews';

export const marketFlowSlice = createSlice({
  name: 'marketFlow',
  initialState: {
    view: flowViews[1],
    data: {},
    cart: [],
    transactionDetails: {},
    isLoading: false,
    error: {},
  },
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    loadingFailed: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    setView: (state, { payload }) => {
      state.view = payload;
      state.isLoading = false;
    },
    setData: (state, { payload }) => {
      state.data = { ...state.data, ...payload };
      state.isLoading = false;
      state.error = {};
    },
    // Select Products to buy out of recommended ones
    addProductToCart: (state, { payload }) => {
      state.isLoading = false;
      const { data } = payload;
      const dataCopy = [...data];
      const selectedProducts = dataCopy.map((element) => ({
        ...element,
        selected: true,
        subTotal: element.discount
          ? element.price - element.discount
          : element.price,
        isInsured: 'No',
      }));
      state.cart = selectedProducts;
    },

    /* Updating the product in the cart. */
    updateProductInCart: (state, { payload }) => {
      state.isLoading = false;
      const product = findProductById(payload, state.cart);
      const productIndex = findProductIndex(payload, state.cart);

      if (productIndex !== -1) {
        const cartCopy = [...state.cart];
        cartCopy[productIndex] = { ...product, selected: true };
        state.cart = cartCopy;
      }
    },

    /* Removing a product from the cart. */
    removeProductFromCart: (state, { payload }) => {
      state.isLoading = false;
      const product = findProductById(payload, state.cart);
      const productIndex = findProductIndex(payload, state.cart);

      if (productIndex !== -1) {
        const cartCopy = [...state.cart];
        cartCopy[productIndex] = { ...product, selected: false };
        state.cart = cartCopy;
      }
    },

    /* Adding insurance to the product in the cart. */
    addProductInsurance: (state, { payload }) => {
      state.isLoading = false;
      const product = findProductById(payload, state.cart);
      const productIndex = findProductIndex(payload, state.cart);

      if (productIndex !== -1) {
        const cartCopy = [...state.cart];
        cartCopy[productIndex] = {
          ...product,
          insurance: formatInsurance(product.price),
          isInsured: 'Yes',
          subTotal: formatInsurance(product.price) + product.price,
        };
        state.cart = cartCopy;
      }
    },

    /* Removing the insurance from the product in the cart. */
    removeProductInsurance: (state, { payload }) => {
      state.isLoading = false;
      const product = findProductById(payload, state.cart);
      const productIndex = findProductIndex(payload, state.cart);

      if (productIndex !== -1) {
        const cartCopy = [...state.cart];
        delete cartCopy[productIndex].insurance;
        cartCopy[productIndex] = {
          ...product,
          subTotal: product.price,
          isInsured: 'No',
        };
        state.cart = cartCopy;
      }
    },

    goToNextStep: (state) => {
      const canGoToNext = true;
      /* loop over form values and don't allow movement to next form if required
       values were not submitted */

      if (canGoToNext === true) {
        // if there is no next view
        if (state.view.progress + 1 > Object.keys(flowViews).length) {
          // do nothing
        } else {
          // get the progress value of the current view
          // use that to put a new view into the state
          state.view = flowViews[(state.view.progress += 1)];
        }
      }
    },
    goToPrevStep: (state) => {
      // if there is no next view
      if (state.view.progress - 1 === 0) {
        // do nothing
      } else {
        // get the progress value of the current view
        // use that to put a new view into the state
        state.view = flowViews[state.view.progress - 1];
      }
    },
    goToView: (state, { payload }) => {
      state.isLoading = payload === 'submitError' ? false : state.isLoading;
      state.view = Object.values(flowViews).find(
        (item) => item.name === payload
      );
    },

    addTransactionDetails: (state, { payload }) => {
      state.transactionDetails = payload;
    },
    clearStateFromRedux: () => {
      localStorage.removeItem('persist:root');
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setView,
  setData,
  addProductToCart,
  updateProductInCart,
  removeProductFromCart,
  addProductInsurance,
  removeProductInsurance,
  startLoading,
  loadingFailed,
  goToNextStep,
  goToPrevStep,
  goToView,
  addTransactionDetails,
  clearStateFromRedux,
} = marketFlowSlice.actions;

export default marketFlowSlice.reducer;
