const deliveryAreas = [
  { name: 'Abule Egba', label: 'Abule Egba', price: 1500 },
  { name: 'Ojo', label: 'Ojo', price: 3500 },
  { name: 'Agege', label: 'Agege', price: 1500 },
  { name: 'Ketu', label: 'Ketu', price: 1200 },
  { name: 'Lekki Phase 2 Axis', label: 'Lekki Phase 2 Axis', price: 3000 },
  { name: 'Oshodi Axis', label: 'Oshodi Axis', price: 1200 },
  { name: 'Festac', label: 'Festac', price: 2000 },
  { name: 'Ikeja', label: 'Ikeja', price: 1000 },
  { name: 'Apapa', label: 'Apapa', price: 2000 },
  { name: 'Ajah Axis', label: 'Ajah Axis', price: 3500 },
  { name: 'Berger & Co', label: 'Berger & Co', price: 1500 },
  { name: 'Ejigbo - Ikotun', label: 'Ejigbo - Ikotun', price: 2000 },
  { name: 'Somolu/Gbagada', label: 'Somolu/Gbagada', price: 1500 },
  { name: 'Ikorodu', label: 'Ikorodu', price: 2500 },
  { name: 'Ikoyi-V.I', label: 'Ikoyi-V.I', price: 2500 },
  { name: 'Ipaja Road', label: 'Ipaja Road', price: 1500 },
  { name: 'Lekki Phase 1 Axis', label: 'Lekki Phase 1 Axis', price: 2500 },
  { name: 'Lagos Island', label: 'Lagos Island', price: 2200 },
  { name: 'Surulere', label: 'Surulere', price: 1500 },
  { name: 'Yaba', label: 'Yaba', price: 1500 },
  { name: 'Badagry', label: 'Badagry', price: 4000 },
  { name: 'Mushin', label: 'Mushin', price: 1500 },
];

export default deliveryAreas;
