/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams, useLocation } from 'react-router-dom';
import { BiSearch } from 'react-icons/bi';
import Button from '../Button';
import Cart from '../../../pages/Cart';
import Categories from '../../Slider';
import { ReactComponent as ShoppingCart } from '../../../../../assets/icons/shooping-cart.svg';
import { ReactComponent as SMGIcon } from '../../../../../assets/icons/smg-short.svg';
import Logo from '../../../../../assets/img/logo.png';
import getQuantity from '../../../helpers/getTotalQuantity';
import FilterContext from '../../../contexts/FilterContext';
import arrow from '../../../../../assets/icons/arrow.svg';
import BlackFridayClock from '../../BlackFriday';
import BlackFridayBanner from '../../BlackFriday/BlackFridayBanner';

const Header = ({ isScrolling, isBlackFriday = false, setNavBarHeightExt }) => {
  const [openCart, setOpenCart] = useState(false);
  const { setTypeFilter } = useContext(FilterContext);
  const removeFocus = useRef();

  const clearFilterHandler = () => {
    setTypeFilter(null);
  };

  const {
    showSearch,
    setShowSearch,
    setSearchQueryText,
    fetchProducts,
    setProducts,
    setLoading,
    searchQueryText,
    setShowQueryText,
    setCount,
    setOffset,
    setDisableLoadMore,
  } = useContext(FilterContext);

  const { productSlug } = useParams();
  const location = useLocation();
  const navRef = useRef();
  const bannerRef = useRef();
  const [navBarHeight, setNavBarHeight] = useState(0);

  useEffect(() => {
    if (!productSlug) {
      setShowSearch(true);
    }
  }, [location.pathname]);

  // Okay, here i just called the entire cart state from the redux store and mapped it
  // just to get the quantity of each product and then summed it all up.
  const cartState = useSelector((state) => state?.shopFlow.products);

  const totalItemsInCart = getQuantity(cartState);

  const search = (e) => {
    setLoading(true);
    if (!e) {
      setShowQueryText(searchQueryText);
      fetchProducts('', isBlackFriday).then((res) => {
        // dispatch(setAllProducts(res.products));
        setDisableLoadMore(false);
        setProducts(res.products);
        setLoading(false);
        setOffset(res.offset);
        // If there is no offset i.e no more products matching the fetch criteria disable load more button
        if (!res.offset) {
          setDisableLoadMore(true);
        }
        setCount(res.count);
      });
    } else {
      removeFocus.current.blur();
      e.preventDefault();
      setShowQueryText(searchQueryText);
      fetchProducts('', isBlackFriday).then((res) => {
        // dispatch(setAllProducts(res.products));
        setDisableLoadMore(false);
        setProducts(res.products);
        setLoading(false);
        setOffset(res.offset);
        // If there is no offset i.e no more products matching the fetch criteria disable load more button
        if (!res.offset) {
          setDisableLoadMore(true);
        }
        setCount(res.count);
      });
    }
  };

  const searchChangeHandler = (e) => {
    setSearchQueryText(e.target.value);
    if (e.target.value === '') {
      search();
      setShowQueryText('');
    }
  };

  useEffect(() => {
    if (navRef) {
      setNavBarHeight(navRef.current.clientHeight);
      if (setNavBarHeightExt && bannerRef) {
        setNavBarHeightExt(
          navRef.current.clientHeight + bannerRef.current.clientHeight
        );
      }
    }
  });

  return (
    <div>
      <nav
        className={`${isScrolling ? '-translate-y-[100vh]' : ''}  ${
          showSearch ? 'pt-12 pb-8' : 'pt-6 pb-2'
        } bg-primary-10 fixed top-0 transition-all duration-[500] left-0 right-0 w-screen z-10 px-4 xl:px-20`}
        ref={navRef}
      >
        <div className="container mx-auto relative">
          <div className="absolute left-0 pt-4 hidden md:hidden md:invisible [@media_(min-width:_1048px)]:visible [@media_(min-width:_1048px)]:flex bottom-4 ">
            <BlackFridayClock />
          </div>
          <div className="flex justify-between items-center mb-4">
            <div>
              <Link to="/" className="sm:inline-flex hidden">
                <img src={Logo} alt="smg-logo" />
              </Link>
              <Link to="/" className="inline-flex sm:hidden">
                <SMGIcon />
              </Link>
            </div>
            {/* <div className="flex items-center justify-center [@media_(min-width:_1048px)]:hidden [@media_(min-width:_1048px)]:invisible ">
            <BlackFridayClock />
          </div> */}

            <div className="flex gap-8 items-center">
              {/* <Link
              to="/"
              className="text-lg font-normal font-Beautiste leading-[22px] text-primary-1 hidden sm:inline"
            >
              Sourcemygadgets Home
            </Link> */}
              <button
                type="button"
                onClick={() => setOpenCart(true)}
                className="flex gap-2 text-lg items-center"
              >
                <ShoppingCart className="text-primary-4" />
                <span className="text-primary-1 font-normal font-Beautiste">
                  Cart
                </span>
                <span className="inline-flex items-start justify-center flex-none h-5 w-5 rounded-[50%] bg-primary-4 text-primary-1 text-sm font-bold">
                  {totalItemsInCart}
                </span>
              </button>
            </div>
          </div>
          {/* cart was moved here because the cart icon moved to the center after "cart empty state" was added. */}
          <Cart isOpen={openCart} setIsOpen={setOpenCart} />

          {showSearch && (
            <>
              <hr className="border-primary-7" />

              <div className={` max-w-3xl mt-4   mx-auto transition-all`}>
                <form
                  onSubmit={search}
                  className="flex relative    justify-center gap-4 "
                >
                  <div className="flex items-center  h-[48px] rounded-lg grow bg-white gap-4">
                    <span className="text-xl pl-5">
                      <BiSearch />
                    </span>
                    <input
                      ref={removeFocus}
                      type="search"
                      className="bg-white grow h-full border-none placeholder:text-gray-5 placeholder:text-[16px] font-Beautiste outline-0 rounded-r-lg"
                      placeholder="Search for Phones, Laptops, etc"
                      onChange={searchChangeHandler}
                    />
                  </div>
                  <Button
                    type="submit"
                    variant="primary"
                    className="w-[152px] hidden sm:block"
                  >
                    Search
                  </Button>
                </form>
                <div className="flex flex-col transition-all mt-4 gap-4 lg:flex-row lg:justify-center sm:items-center">
                  <h5 className="text-gray-1 text-sm font-bold tracking-tightest">
                    Categories
                  </h5>
                  <div className="grow px-4 w-[100%]  lg:w-[55%] ">
                    <Categories />
                  </div>
                  <div className="flex">
                    <button
                      className="flex flex-none gap-2 bg-primary-8 items-center text-sm font-normal text-primary-1 font-Karla py-2 px-3 rounded-[8px] shadow-[0px_1px_16px_rgba(0,28,61,0.04),_0px_0px_2px_rgba(10,38,71,0.05)]"
                      type="button"
                      onClick={clearFilterHandler}
                    >
                      <img src={arrow} alt="" />
                      Clear Selection
                    </button>
                  </div>
                </div>
                {/* <div className="w-full flex justify-center items-center absolute left-0 bg-primary-10 [@media_(min-width:_1048px)]:hidden [@media_(min-width:_1048px)]:invisible ">
                <div className="-translate-y-[20px]">
                  <BlackFridayClock />
                </div>
              </div> */}
              </div>
            </>
          )}
        </div>
      </nav>
      {isBlackFriday ? (
        <div
          style={{
            marginTop: isScrolling ? '' : `${navBarHeight}px`,
            transform: isScrolling ? 'translateY(-1000vh%)' : 'block',
          }}
        />
      ) : !showSearch ? (
        ''
      ) : (
        <div
          style={{
            marginTop: isScrolling ? '0px' : `${navBarHeight}px`,
            transform: isScrolling ? 'translateY(-1000vh)' : 'translateY(0)',
          }}
          ref={bannerRef}
        >
          <BlackFridayBanner />
        </div>
      )}
    </div>
  );
};

export default Header;
