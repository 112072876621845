import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import { FaBoxOpen, FaMoneyBillWave } from 'react-icons/fa';
import { BiChevronDown } from 'react-icons/bi';
import { GiHamburgerMenu } from 'react-icons/gi';
import { TiSpanner } from 'react-icons/ti';
import { BsShieldFill } from 'react-icons/bs';
import { RiExchangeFill } from 'react-icons/ri';
import { MdVerified } from 'react-icons/md';
import MobileNavigation from './MobileNavigation';
import Logo from '../../../assets/img/smg-logo.png';

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const location = window.location.pathname;

  const openMenu = () => {
    setOpen(!open);
  };

  const individualSubmenu = () => (
    <Menu>
      <Menu.Item>
        <Link
          to="/individual/recommendation-engine"
          className="flex items-center gap-x-6"
        >
          <div className="inline-flex flex-none h-12 w-12 bg-white text-primary-4 text-xl justify-center items-center">
            <MdVerified />
          </div>
          <div className="flex flex-col grow gap-[0.5rem]">
            <h3 className="text-lg text-primary-10 font-Karla font-bold">
              Recommendation Engine
            </h3>
            <p className="text-sm text-gray-6 font-Beautiste font-normal leading-[17px]">
              Best-suiting gadgets within your budget.
            </p>
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/individual/pay-later" className="flex gap-x-6 items-center">
          <div className="inline-flex flex-none h-12 w-12 bg-white text-accent-4 text-xl justify-center items-center">
            <FaMoneyBillWave />
          </div>
          <div className="flex flex-col grow gap-[0.5rem]">
            <h3 className="text-lg text-primary-10 font-Karla font-bold">
              Buy Now, Pay later
            </h3>
            <p className="text-sm text-gray-6 font-Beautiste font-normal leading-[17px]">
              Assist your purchase.
            </p>
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/individual/insurance" className="flex gap-x-6 items-center">
          <div className="inline-flex flex-none h-12 w-12 bg-white text-validation-1 text-xl justify-center items-center">
            <BsShieldFill />
          </div>
          <div className="flex flex-col grow gap-[0.5rem]">
            <h3 className="text-lg text-primary-10 font-Karla font-bold">
              Insurance
            </h3>
            <p className="text-sm text-gray-6 font-Beautiste font-normal leading-[17px]">
              Protection against Theft, Malfunction or Damage.
            </p>
          </div>
        </Link>
      </Menu.Item>
    </Menu>
  );

  const businessSubMenu = () => (
    <Menu>
      <Menu.Item>
        <Link to="/business/bulk" className="flex items-center gap-x-6">
          <div className="inline-flex flex-none h-12 w-12 text-accent-3 text-xl bg-accent-1 rounded-[50%] justify-center items-center ">
            <FaBoxOpen />
          </div>
          <div className="flex flex-col grow gap-[0.5rem]">
            <h3 className="text-lg text-primary-10 font-Karla font-bold">
              Quality Gadgets in Bulk
            </h3>
            <p className="text-sm text-gray-6 font-Beautiste font-normal leading-[17px]">
              Best-suiting gadgets within your budget.
            </p>
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/business/repair" className="flex items-center gap-x-6">
          <div className="inline-flex flex-none h-12 w-12 bg-accent-1 text-accent-3 rounded-[50%] text-xl justify-center items-center">
            <TiSpanner />
          </div>
          <div className="flex flex-col grow gap-[0.5rem]">
            <h3 className="text-lg text-primary-10 font-Karla font-bold">
              Repair and Maintenance
            </h3>
            <p className="text-sm text-gray-6 font-Beautiste font-normal leading-[17px]">
              Technical support for malfunction and recurring device health
              maintenance.
            </p>
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/business/trade-in" className="flex items-center gap-x-6">
          <div className="inline-flex flex-none h-12 w-12 bg-accent-1 text-accent-3 rounded-[50%] text-xl justify-center items-center">
            <RiExchangeFill />
          </div>
          <div className="flex flex-col grow gap-[0.5rem]">
            <h3 className="text-lg text-primary-10 font-Karla font-bold">
              Trade-In Solutions
            </h3>
            <p className="text-sm text-gray-6 font-Beautiste font-normal leading-[17px]">
              Exchange devices for cash in singles or bulk.
            </p>
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/business/insurance" className="flex items-center gap-x-6">
          <div className="inline-flex flex-none h-12 w-12 bg-accent-1 text-accent-3 rounded-[50%] text-xl justify-center items-center">
            <BsShieldFill />
          </div>
          <div className="flex flex-col grow gap-[0.5rem]">
            <h3 className="text-lg text-primary-10 font-Karla font-bold">
              Gadget Insurance
            </h3>
            <p className="text-sm text-gray-6 font-Beautiste font-normal leading-[17px]">
              Protection against Theft, Malfunction or Damage.
            </p>
          </div>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <nav className="sticky top-0 bg-white z-20 shadow-sm">
      {/* Desktop Navigation */}
      <div
        className="flex items-center justify-between py-3 px-8 lg:px-24 2xl:px-40"
        data-test="header-component"
      >
        <div className="flex w-full gap-8 items-center justify-between">
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={openMenu}
              className="bg-gray-1 p-3 rounded-lg text-primary-4"
            >
              <GiHamburgerMenu />
            </button>
          </div>
          <Link to="/" className="flex-none">
            <img data-test="smg-logo" src={Logo} alt="smg logo" />
          </Link>
          <div className="lg:flex justify-between grow items-center hidden">
            <div className="flex gap-x-8">
              <Link
                data-test="contact"
                to="/contact"
                className="font-Beautiste text-gray-6 lg:text-sm xl:text-base"
              >
                Contact
              </Link>
              <Link
                data-test="about"
                to="/about"
                className="font-Beautiste text-gray-6 lg:text-sm xl:text-base"
              >
                About Us
              </Link>
            </div>
            <div className="flex gap-8 items-center" data-test="smg-links">
              <Dropdown
                overlay={individualSubmenu}
                placement="bottom"
                overlayClassName="individual-dropdown max-w-[379px]"
              >
                <Link
                  data-test="individual"
                  to="/individual"
                  className="font-Beautiste text-gray-6 flex items-center lg:text-sm xl:text-base"
                >
                  For Individuals <BiChevronDown />
                </Link>
              </Dropdown>
              <Dropdown
                overlay={businessSubMenu}
                placement="bottom"
                overlayClassName="business-dropdown max-w-[379px]"
              >
                <Link
                  data-test="business"
                  to="/business"
                  className="font-Beautiste text-gray-6 flex items-center lg:text-sm xl:text-base"
                >
                  For Business <BiChevronDown />
                </Link>
              </Dropdown>

              {location.includes('/business') ? (
                <Link
                  to="/business/request-quote"
                  className="font-Karla font-semibold bg-accent-3 text-white px-4 py-2.5 rounded-md text-sm  hover:bg-accent-4 hover:text-white transition duration-300"
                >
                  Request a Quote
                </Link>
              ) : (
                <Link
                  to="/recommend"
                  className="font-Karla font-semibold bg-primary-4 text-white px-4 py-2.5 rounded-md text-sm  hover:bg-primary-5 hover:text-white transition duration-300"
                >
                  Get a Recommendation
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Mobile Navigation */}
      <MobileNavigation open={open} />
    </nav>
  );
};

export default Navbar;
