import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';
import { IoIosInformationCircle } from 'react-icons/io';
import { FaMinus, FaPlus } from 'react-icons/fa';
import {
  toggleInsurance,
  increaseQuantity,
  decreaseQuantity,
  removeFromCart,
} from '../../../../redux/shopFlow/addToCartSlice';
import { Button } from '../common';
import { formatPrice } from '../../helpers';

const LearnMore = () => (
  <>
    <span className="font-normal text-[10px] text-gray-2 font-Beautiste">
      Insurance covers damage or theft on your device. Insurance Costs 5% on
      your purchase.
    </span>
    <a
      href="/learn-more"
      target="_blank"
      className="text-[10px] font-Beautiste text-primary-4 block"
    >
      Learn More
    </a>
  </>
);

const CartItem = ({ item }) => {
  const dispatch = useDispatch();

  const insuranceFee = 0.05 * item.price * (item.quantity ? item.quantity : 1);

  const handleToggleInsurance = (payload) => {
    dispatch(toggleInsurance(payload));
  };

  const handleDecreaseQuantity = (payload) => {
    dispatch(decreaseQuantity(payload));
  };

  const handleIncreaseQuantity = (payload) => {
    dispatch(increaseQuantity(payload));
  };

  const handleRemoveItemFromCart = (payload) => {
    dispatch(removeFromCart(payload));
  };

  return (
    <div className="flex flex-col  w-full border border-[#E0E6ED] rounded-lg p-4 gap-x-8 lg:flex-row">
      <div className="inline-flex h-[240px] w-full bg-gray-1 rounded-lg lg:w-[200px]">
        <img
          src={item.images ? item.images[0].url : ''}
          className="w-full h-full rounded-lg object-contain"
          alt={item.name}
        />
      </div>
      <div className="flex flex-col justify-center mt-8 lg:mt-0">
        <div>
          <span className="inline-flex flex-none items-center justify-center uppercase text-gray-5 text-xs font-Beautiste font-normal py-1 px-2 bg-gray-2 border border-gray-4 rounded-2xl">
            {item?.state || 'New'}
          </span>
        </div>
        <h4 className="font-normal text-sm text-gray-6 font-Beautiste mt-[18px]">
          {item?.name}
        </h4>
        <h3 className="font-bold font-Karla text-lg text-primary-10 mt-2">
          &#8358;
          {item.insurance === true
            ? formatPrice(
                item.price * (item.quantity ? item.quantity : 1) + insuranceFee
              )
            : formatPrice(item.price * (item.quantity ? item.quantity : 1))}
        </h3>
        {item.type !== 'Accessories' &&
        item.subType !== 'Games' &&
        item.subType !== 'Gaming Accessories' ? (
          <>
            <label className="flex items-center gap-4 mt-4">
              <input
                type="checkbox"
                onChange={() => handleToggleInsurance(item.id)}
                checked={item.insurance}
                className="h-4 w-4 accent-primary-5"
              />
              <span className="text-gray-6 text-sm">
                {!item.insurance ? (
                  <span>Apply Insurance. </span>
                ) : (
                  <span>Insurance Applied. </span>
                )}
                This costs &#8358;{formatPrice(insuranceFee.toFixed(2))} extra.
              </span>
            </label>
            <Tooltip
              title={<LearnMore />}
              color="#001C3D"
              placement="topLeft"
              overlayInnerStyle={{ borderRadius: '8px', marginTop: '12px' }}
            >
              <span className="inline-flex items-center gap-2 text-gray-5 text-[10px] mt-3">
                <IoIosInformationCircle /> How does insurance Work?
              </span>
            </Tooltip>
          </>
        ) : (
          ''
        )}
        <div className="flex items-center gap-2 mt-4">
          <Button
            type="button"
            variant="secondary"
            onClick={() => handleDecreaseQuantity(item.id)}
            className="disabled:cursor-not-allowed h-9"
          >
            <FaMinus />
          </Button>
          <span className="text-sm font-bold font-Karla text-primary-10">
            {item.quantity ? item.quantity : 1}
          </span>
          <Button
            type="button"
            variant="secondary"
            onClick={() => handleIncreaseQuantity(item.id)}
            className="h-9"
          >
            <FaPlus />
          </Button>
          <Button
            type="button"
            variant="secondary"
            className="h-9 font-bold flex items-center"
            onClick={() => handleRemoveItemFromCart(item.id)}
          >
            Remove
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
