import { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { RiShoppingCartFill } from 'react-icons/ri';
import { CartItem } from '../../components';
import Delivery from '../Delivery';
import { Drawer, Button } from '../../components/common';
import { ReactComponent as Cartimg } from './cart.svg';
import totalPrice from '../SingleProduct/helpers/totalPrice';
import { setCartTotal } from '../../../../redux/shopFlow/addToCartSlice';
import { formatPrice } from '../../helpers';

const Cart = ({ isOpen, setIsOpen }) => {
  const [openDelivery, setOpenDelivery] = useState(false);
  const cartFocusRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartItems = useSelector((product) => product.shopFlow.products);

  const TOTAL_PRICE_OF_ITEMS_IN_CART = totalPrice(cartItems);

  return (
    <div>
      {cartItems.length === 0 ? (
        <Drawer
          title="Cart"
          openModal={isOpen}
          setOpenModal={setIsOpen}
          autoFocusRef={cartFocusRef}
        >
          <div className="my-8 flex flex-col gap-4">
            <span className="flex items-center justify-center">
              <Cartimg className="mb-10 mt-10" />
            </span>
            <p
              className="flex items-center justify-center font-bold text-3xl font-Karla text-[24px]"
              style={{ color: '#082546' }}
            >
              Your Cart is Empty
            </p>
            <p
              className="flex items-center justify-center text-sm font-Beautiste"
              style={{ color: '#082546' }}
            >
              Seems like you have not added anything to your cart yet
            </p>
          </div>

          <div className="flex items-center justify-center gap-4 mt-16 ">
            <Button
              variant="primary"
              onClick={() => {
                navigate('/shop');
                setIsOpen(false);
              }}
              className="w-2/3"
            >
              Continue Shopping
            </Button>
          </div>
        </Drawer>
      ) : (
        <div>
          <Drawer
            title="Cart"
            openModal={isOpen}
            setOpenModal={setIsOpen}
            autoFocusRef={cartFocusRef}
          >
            <div className="my-8 flex flex-col gap-4">
              {cartItems.map((item) => (
                <CartItem key={item.id} item={item} />
              ))}
            </div>

            <div className="flex flex-col lg:flex-row gap-4 mt-16">
              <Button
                variant="inverse-primary"
                onClick={() => {
                  navigate('/shop');
                  setIsOpen(false);
                }}
                className="w-full"
              >
                Continue Shopping
              </Button>
              <Button
                variant="primary"
                className="w-full"
                onClick={() => {
                  dispatch(setCartTotal(TOTAL_PRICE_OF_ITEMS_IN_CART));
                  setOpenDelivery(true);
                }}
              >
                Pay (&#8358;{formatPrice(TOTAL_PRICE_OF_ITEMS_IN_CART)})
              </Button>
            </div>
          </Drawer>
          <Delivery isOpen={openDelivery} setIsOpen={setOpenDelivery} />
        </div>
      )}
    </div>
  );
};

export default Cart;
