import { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import { Select, DatePicker, Radio } from 'formik-antd';
import moment from 'moment';
import DeliverySchema from './validationSchema';
import { Drawer, Button } from '../../components/common';
import ProductItem from './ProductItem';
import { formatPrice } from '../../helpers';
import deliveryAreas from '../../utils/deliveryAreas';
import totalPrice from '../SingleProduct/helpers/totalPrice';
import Payment from '../Payment';
import {
  setDeliveryDetails,
  setCartTotal,
} from '../../../../redux/shopFlow/addToCartSlice';
import { BLACKFRIDAY_PARAMS } from '../../components/BlackFriday';

const { Option } = Select;

const Delivery = ({ isOpen, setIsOpen }) => {
  const [paymentModal, setPaymentModal] = useState(false);
  const [pickupMethod, setPickupMethod] = useState('Delivery');

  const dispatch = useDispatch();
  const deliveryFocusRef = useRef(null);

  const { products } = useSelector((product) => product.shopFlow);

  const formDetails = JSON.parse(localStorage.getItem('deliveryDetails'));

  const handleToPaymentModal = () => {
    // console.log(errors);
    // const formErrors = Object.keys(errors).length;
    // if (formErrors <= 0) {
    setPaymentModal(true);
    // }
  };

  const TOTAL_PRICE_OF_ITEMS_IN_CART = totalPrice(products);
  /**
   * If the current date is less than yesterday, return true.
   */
  const dateValidation = (current) =>
    current && current < moment().subtract(1, 'days');

  return (
    <div>
      <Drawer
        title="Delivery Details"
        openModal={isOpen}
        setOpenModal={setIsOpen}
        autoFocusRef={deliveryFocusRef}
      >
        <Formik
          initialValues={
            formDetails
              ? {
                  firstname: formDetails.firstname,
                  lastname: formDetails.lastname,
                  email: formDetails.email,
                  phone: formDetails.phone,
                  area: { ...formDetails.area },
                  deliveryDate: formDetails.deliveryDate,
                  address: formDetails.address,
                  orderType: 'Delivery',
                  pickupDate: formDetails.pickupDate,
                }
              : {
                  firstname: '',
                  lastname: '',
                  email: '',
                  phone: '',
                  area: '',
                  deliveryDate: '',
                  address: '',
                  orderType: 'Delivery',
                  pickupDate: '',
                }
          }
          validationSchema={
            pickupMethod === 'Delivery'
              ? DeliverySchema.withDelivery
              : DeliverySchema.withPickup
          }
          onSubmit={(values) => {
            const {
              firstname,
              lastname,
              email,
              phone,
              area,
              deliveryDate,
              address,
              pickupDate,
            } = values;

            dispatch(
              setDeliveryDetails({
                firstname,
                lastname,
                email,
                phone,
                area,
                deliveryDate,
                address,
                pickupDate,
                orderType: values.orderType,
              })
            );

            localStorage.setItem('deliveryDetails', JSON.stringify(values));
            if (values.orderType === 'Pickup') {
              dispatch(setCartTotal(Number(TOTAL_PRICE_OF_ITEMS_IN_CART)));
            } else if (
              values.orderType === 'Delivery' &&
              Number(TOTAL_PRICE_OF_ITEMS_IN_CART) > 10000 &&
              BLACKFRIDAY_PARAMS.startDate < BLACKFRIDAY_PARAMS.today &&
              BLACKFRIDAY_PARAMS.endDate > BLACKFRIDAY_PARAMS.today
            ) {
              dispatch(setCartTotal(Number(TOTAL_PRICE_OF_ITEMS_IN_CART)));
            } else {
              dispatch(
                setCartTotal(
                  Number(TOTAL_PRICE_OF_ITEMS_IN_CART) + Number(area.price)
                )
              );
            }

            handleToPaymentModal();
          }}
        >
          {({ values, touched, errors, setFieldValue }) => (
            <Form>
              <div className="my-8 flex flex-col gap-8">
                <div className="grid grid-cols-1 gap-6 xl:grid-cols-2">
                  <div>
                    <div className="mt-4 relative">
                      <label
                        className="block text-gray-7 font-Karla text-sm font-semibold mb-2 lg:font-semibold lg:text-sm"
                        htmlFor="firstname"
                      >
                        First Name
                      </label>
                      <Field
                        id="firstname"
                        className={`${
                          errors.firstname && touched.firstname
                            ? 'border-red-500'
                            : 'border-gray-3'
                        } w-full appearance-none bg-gray-1 text-sm focus:text-base text-gray-6 shadow-none border-[.5px] rounded-md py-2 px-4 focus:outline-none focus:border-primary-8`}
                        name="firstname"
                        type="text"
                        placeholder="Greg"
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="text-red-700 text-xs mt-1">{msg}</div>
                      )}
                      name="firstname"
                    />
                  </div>
                  <div>
                    <div className="mt-4 relative">
                      <label
                        className="block text-gray-7 font-Karla text-sm font-semibold mb-2 lg:font-semibold lg:text-sm"
                        htmlFor="lastname"
                      >
                        Last Name
                      </label>
                      <Field
                        className={`${
                          errors.lastname && touched.lastname
                            ? 'border-red-500'
                            : 'border-gray-3'
                        } w-full appearance-none bg-gray-1 text-sm focus:text-base text-gray-6 shadow-none border-[.5px] rounded-md py-2 px-4 focus:outline-none focus:border-primary-8`}
                        name="lastname"
                        type="text"
                        placeholder="Neal"
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="text-red-700 text-xs mt-1">{msg}</div>
                      )}
                      name="lastname"
                    />
                  </div>
                </div>
                <div className="flex w-full">
                  <div className="w-full appearance-none">
                    <div className="relative">
                      <label
                        className="block text-gray-7 font-Karla text-sm font-semibold mb-2 lg:font-semibold lg:text-sm"
                        htmlFor="email"
                      >
                        Email Address
                      </label>
                      <Field
                        className={`${
                          errors.email && touched.email
                            ? 'border-red-500'
                            : 'border-gray-3'
                        } w-full appearance-none bg-gray-1 text-sm focus:text-base text-gray-6 shadow-none border-[.5px] rounded-md py-2 px-4 focus:outline-none focus:border-primary-8`}
                        name="email"
                        type="text"
                        placeholder="greg.neal@example.com"
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="text-red-700 text-xs mt-1">{msg}</div>
                      )}
                      name="email"
                    />
                  </div>
                </div>
                <div className="flex w-full">
                  <div className="w-full appearance-none">
                    <div className="relative">
                      <label
                        className="block text-gray-7 font-Karla text-sm font-semibold mb-2 lg:font-semibold lg:text-sm"
                        htmlFor="phone"
                      >
                        Phone Number
                      </label>
                      <Field
                        className={`${
                          errors.phone && touched.phone
                            ? 'border-red-500'
                            : 'border-gray-3'
                        } w-full appearance-none bg-gray-1 text-sm focus:text-base text-gray-6 shadow-none border-[.5px] rounded-md py-2 px-4 focus:outline-none focus:border-primary-8`}
                        name="phone"
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, '');
                          setFieldValue('phone', value);
                        }}
                        // placeholder="+234 812 345 6789"
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="text-red-700 text-xs mt-1">{msg}</div>
                      )}
                      name="phone"
                    />
                  </div>
                </div>
                <div className="">
                  <h4 className="block text-gray-7 font-Karla text-sm font-semibold mb-2 lg:font-semibold lg:text-sm">
                    Delivery Method
                  </h4>
                  <Radio.Group
                    onChange={(e) => {
                      if (e.target.value === 'Pickup') {
                        setFieldValue('area', {});
                      }
                      setPickupMethod(e.target.value);
                    }}
                    name="orderType"
                  >
                    <Radio value="Delivery">Delivery</Radio>
                    <Radio value="Pickup">Pick Up</Radio>
                  </Radio.Group>
                </div>
                {values.orderType === 'Delivery' && (
                  <>
                    <div className="flex w-full">
                      <div className="w-full">
                        <div className="mt-1 relative">
                          <label
                            className="block text-gray-7 font-Karla text-sm font-semibold mb-2 lg:font-semibold lg:text-sm"
                            htmlFor="area"
                          >
                            Area
                          </label>
                          <Select
                            bordered={false}
                            className={`${
                              errors.area && touched.area
                                ? 'border-red-500'
                                : 'border-gray-3'
                            } w-full appearance-none bg-gray-1 text-sm focus:text-base text-gray-6 shadow-none border-[.5px] rounded-md py-2 px-4 focus:outline-none focus:border-primary-8`}
                            name="area"
                            onChange={(e) => {
                              const deliveryArea = deliveryAreas.find(
                                (a) => a.name === e
                              );
                              setFieldValue('area', deliveryArea);
                            }}
                            placeholder="Select"
                          >
                            {deliveryAreas.map((area) => (
                              <Option
                                value={area.name}
                                key={area.name}
                                className="font-Beautiste text-sm mr-6"
                              >
                                {area.label}
                              </Option>
                            ))}
                          </Select>
                        </div>
                        <ErrorMessage
                          render={(msg) => (
                            <div className="text-red-700 text-xs mt-1">
                              {msg}
                            </div>
                          )}
                          name="area"
                        />
                      </div>
                    </div>
                    <div className="">
                      <div className="flex items-center justify-between gap-4 w-full">
                        <div className="w-full">
                          <div className="mt-4 relative w-full">
                            <label
                              className="block font-Karla font-bold text-sm text-gray-4 uppercase mb-2"
                              htmlFor="address"
                            >
                              Delivery Address
                            </label>
                            <Field
                              className={`${
                                errors.address && touched.address
                                  ? 'border-red-500'
                                  : 'border-gray-3'
                              } w-full appearance-none bg-gray-1 text-sm focus:text-base text-gray-6 shadow-none border-[.5px] rounded-md py-2 px-4 focus:outline-none focus:border-primary-8`}
                              name="address"
                              as="textarea"
                              rows="2"
                              placeholder="326, Herbert Macaulay Way, Yaba, Lagos State."
                            />
                          </div>
                          <ErrorMessage
                            render={(msg) => (
                              <div className="text-red-700 text-xs mt-1">
                                {msg}
                              </div>
                            )}
                            name="address"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <h6 className="font-Karla font-bold text-sm text-gray-4 uppercase">
                        Expected Delivery Date
                      </h6>
                      <div className="flex items-center justify-between gap-4">
                        <div className="w-full">
                          <DatePicker
                            // onChange={(date, dateString) => setFieldValue(dateString)}
                            className={`${
                              errors.deliveryDate && touched.deliveryDate
                                ? 'border-red-500'
                                : 'border-gray-3'
                            } w-full appearance-none bg-gray-1 text-sm focus:text-base text-gray-6 shadow-none border-[.5px] rounded-md py-2 px-4 focus:outline-none focus:border-primary-8`}
                            name="deliveryDate"
                            format="DD MMMM YYYY"
                            disabledDate={dateValidation}
                          />
                          <ErrorMessage
                            render={(msg) => (
                              <div className="text-red-700 text-xs mt-1">
                                {msg}
                              </div>
                            )}
                            name="deliveryDate"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {values.orderType === 'Pickup' && (
                  <div className="">
                    <h6 className="font-Karla font-bold text-sm text-gray-4 uppercase">
                      Pick Up Date
                    </h6>
                    <div className="flex items-center justify-between gap-4">
                      <div className="w-full">
                        <DatePicker
                          showTime={{
                            defaultValue: moment('10:00', 'HH:mm a'),
                          }}
                          disabledTime={() => ({
                            disabledHours: () => [
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 16, 17, 18, 19, 20,
                              21, 22, 23,
                            ],
                          })}
                          // onChange={(date, dateString) => setFieldValue(dateString)}
                          className={`${
                            errors.pickupDate && touched.pickupDate
                              ? 'border-red-500'
                              : 'border-gray-3'
                          } w-full appearance-none bg-gray-1 text-sm focus:text-base text-gray-6 shadow-none border-[.5px] rounded-md py-2 px-4 focus:outline-none focus:border-primary-8`}
                          name="pickupDate"
                          format="DD MMMM YYYY HH:mm"
                          disabledDate={dateValidation}
                        />
                        <ErrorMessage
                          render={(msg) => (
                            <div className="text-red-700 text-xs mt-1">
                              {msg}
                            </div>
                          )}
                          name="pickupDate"
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex flex-col gap-4">
                  <h6 className="font-Karla font-bold text-sm text-gray-4 uppercase">
                    Subtotal
                  </h6>
                  {products.map((item) => (
                    <ProductItem key={item.id} item={item} />
                  ))}
                  {values.orderType === 'Delivery' && values.area.price && (
                    <div className="flex items-center justify-between gap-4">
                      <p className="font-Beautiste font-normal text-gray-6 text-base">
                        Delivery
                      </p>

                      {Number(TOTAL_PRICE_OF_ITEMS_IN_CART) > 10000 &&
                      BLACKFRIDAY_PARAMS.startDate < BLACKFRIDAY_PARAMS.today &&
                      BLACKFRIDAY_PARAMS.endDate > BLACKFRIDAY_PARAMS.today ? (
                        <h6 className="flex-none text-lg font-bold text-gray-6 font-Karla">
                          Free
                        </h6>
                      ) : (
                        <h6 className="flex-none text-lg font-bold text-gray-6 font-Karla">
                          &#8358;{formatPrice(values.area.price?.toFixed(2))}
                        </h6>
                      )}
                    </div>
                  )}
                </div>
                <hr />
                <div className="flex justify-end">
                  <div className="text-right">
                    <h6 className="font-Karla font-bold text-sm text-gray-4 uppercase">
                      Total
                    </h6>
                    <h2 className="text-xl font-bold text-primary-10 font-Karla">
                      &#8358;
                      {values.orderType === 'Delivery' &&
                      Number(TOTAL_PRICE_OF_ITEMS_IN_CART) > 10000 &&
                      BLACKFRIDAY_PARAMS.startDate < BLACKFRIDAY_PARAMS.today &&
                      BLACKFRIDAY_PARAMS.endDate > BLACKFRIDAY_PARAMS.today
                        ? formatPrice(Number(TOTAL_PRICE_OF_ITEMS_IN_CART))
                        : formatPrice(
                            Number(TOTAL_PRICE_OF_ITEMS_IN_CART) +
                              Number(values.area.price || 0)
                          )}
                    </h2>
                  </div>
                </div>
                <div className="flex flex-col gap-4 mt-8 lg:flex-row lg:gap-8">
                  <Button
                    // ref={deliveryFocusRef}
                    variant="inverse-primary"
                    className="w-full p-5"
                    type="button"
                    onClick={() => setIsOpen(false)}
                  >
                    Back
                  </Button>
                  <Button
                    variant="primary"
                    className="w-full p-5"
                    type="submit"
                    disabled={Object.keys(errors).length > 0}
                  >
                    Pay &#8358;
                    {values.orderType === 'Delivery' &&
                    Number(TOTAL_PRICE_OF_ITEMS_IN_CART) > 10000 &&
                    BLACKFRIDAY_PARAMS.startDate < BLACKFRIDAY_PARAMS.today &&
                    BLACKFRIDAY_PARAMS.endDate > BLACKFRIDAY_PARAMS.today
                      ? formatPrice(Number(TOTAL_PRICE_OF_ITEMS_IN_CART))
                      : formatPrice(
                          Number(TOTAL_PRICE_OF_ITEMS_IN_CART) +
                            Number(values.area.price || 0)
                        )}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Drawer>
      <Payment paymentModal={paymentModal} setPaymentModal={setPaymentModal} />
    </div>
  );
};

export default Delivery;
