import { useEffect, useRef, useState } from 'react';
import { BsWhatsapp } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Drawer, Button } from '../../components/common';
import { ReactComponent as ClockIcon } from '../../../../assets/icons/clock.svg';
import { formatPrice } from '../../../../helpers';
import getQuantity from '../../helpers/getTotalQuantity';
import { clearCart } from '../../../../redux/shopFlow/addToCartSlice';

const PaymentProcessing = ({
  response,
  setPaymentResponseModal,
  referenceId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const paymentProcessingResponseFocusRef = useRef();

  const rootElement = document.getElementById('headlessui-portal-root');
  const closeAllModal = () => {
    if (rootElement) {
      rootElement.remove();
    }
    setTimeout(() => {
      dispatch(clearCart());
    }, 2000);
  };

  const { products, cartTotal, deliveryDetails } = useSelector(
    (product) => product.shopFlow
  );

  const TOTAL_NUMBER_OF_ITEMS_IN_CART = getQuantity(products);
  const TOTAL_PRICE_OF_ITEMS_IN_CART = cartTotal;

  const currentDate = moment().format('DD MMM YYYY');

  useEffect(() => {
    if (response === true) {
      setIsOpen(true);
    }
    if (response === false) {
      setIsOpen(false);
    }

    setTimeout(() => {
      setPaymentResponseModal(null);
    }, 100);
  }, [response]);

  return (
    <Drawer
      title="Payment Processing"
      openModal={isOpen}
      setOpenModal={setIsOpen}
      autoFocusRef={paymentProcessingResponseFocusRef}
    >
      <div className="my-8 flex flex-col max-w-md mx-auto">
        <div className="flex flex-col gap-4 w-full items-center justify-center">
          <div className="flex w-full justify-center">
            <ClockIcon />
          </div>
          <h4 className="text-2xl font-bold font-Karla text-primary-8">
            Your Transfer is processing
          </h4>
          <p className="text-sm font-normal text-center text-[#0A2647]">
            A Sales Representative will contact you to confirm your order and
            give you more details on delivery in about 5 minutes.{' '}
          </p>
          <div className="border-y-4 w-full bg-white border-gray-1 border-dashed shadow-[0px_8px_64px_rgba(0,19,88,0.1)] rounded-lg p-4 mt-8">
            <h5 className="font-bold text-lg text-gray-6 font-Karla">
              Payment Reciept for {TOTAL_NUMBER_OF_ITEMS_IN_CART} Item(s)
            </h5>
            <h6 className="text-lg font-normal font-Karla text-gray-6">
              {currentDate}
            </h6>
            <div className="flex mt-4 justify-between">
              <h5 className="text-lg font-normal font-Karla text-gray-6">
                Payment Reference
              </h5>
              <h6 className="font-bold text-lg text-gray-6 font-Karla">
                #{referenceId}
              </h6>
            </div>
            <div className="mt-4">
              <h5 className="uppercase font-bold text-sm text-gray-4 font-Karla">
                Subtotal
              </h5>
              {products?.map((item) => (
                <div key={item.id} className="flex justify-between my-1">
                  <h5 className="text-lg font-normal font-Karla text-gray-6 break-words">
                    {item.name}
                  </h5>
                  <h6 className="font-bold text-lg text-gray-6 font-Karla">
                    &#8358;
                    {item.insurance === true
                      ? formatPrice(
                          item.price * (item.quantity ? item.quantity : 1) +
                            0.05 * item.price * item.quantity
                        )
                      : formatPrice(
                          item.price * (item.quantity ? item.quantity : 1)
                        )}
                  </h6>
                </div>
              ))}
            </div>
            <hr />
            <div className="flex justify-between my-1">
              <h5 className="text-lg font-normal font-Karla text-gray-6 break-words">
                Delivery
              </h5>
              <h6 className="font-bold text-lg text-gray-6 font-Karla">
                &#8358;{formatPrice(deliveryDetails?.area?.price)}
              </h6>
            </div>
            <div className="flex flex-col items-end uppercase mt-4">
              <h5 className="font-bold text-sm font-Karla text-gray-4">
                Total
              </h5>
              <h3 className="font-bold font-Karla text-xl text-primary-10">
                &#8358;{formatPrice(TOTAL_PRICE_OF_ITEMS_IN_CART)}
              </h3>
            </div>
          </div>
          <div className="w-full">
            <h5 className="text-lg font-Karla text-primary-10 mt-8">
              Didn’t get a response yet?
            </h5>
          </div>
          <div className="flex w-full">
            <a
              href={`https://wa.me/2349052821496?text=${encodeURIComponent(
                `Hi, my name is "Your Name" I just made payment for some gadgets`
              )}`}
              className="flex w-full justify-center mt-6 items-center gap-4 bg-primary-4 hover:text-white text-white py-3 px-4 rounded-lg"
            >
              <span>
                <BsWhatsapp />
              </span>
              <span className="font-Karla font-semibold">
                Chat with a Sales Rep on WhatsApp
              </span>
            </a>
          </div>
          <Button
            onClick={() => closeAllModal()}
            variant="secondary"
            className="w-full"
          >
            Close
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default PaymentProcessing;
