const highlight = {
  heading: 'SOURCEMYGADGETS PRIVACY POLICY',
  intro:
    'This Privacy Policy provides information on how the SOURCEMYGADGET TECHNOLOGIES LIMITED (“SOURCEMYGADGETS”) collects and processes your personal data when you visit our website, use our mobile applications or our other platforms.',
  title: '1.	Your Privacy Rights',
  options: [
    {
      list: 'a)	This Privacy Policy describes your privacy rights regarding our collection, use, storage, sharing and protection of your personal information. It applies to the SOURCEMYGADGETS website and all database, (mobile) applications, services, tools and physical contact with us, regardless of how you access or use them.',
    },
    {
      list: 'b)	If you have created a username, identification code, password or any other piece of information as part of our access security measures, you must treat such information as confidential, and you must not disclose it to any third party. ',
    },
    {
      list: 'c)	We reserve the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our opinion you have failed to comply with any of the provisions of this privacy policy. ',
    },
    {
      list: 'd)	If you know or suspect that anyone other than you know your security details, you must promptly notify us at hello@SourceMyGadgetss.com ',
    },
  ],

  consent: '2.	Consent',
  consentBody:
    'You accept this privacy policy when you give consent upon access to our platforms, or use our services, content, features, technologies or functions offered on our website, digital platforms or visit any of our offices for official or non-official purposes (collectively the “SOURCEMYGADGETS Services”). This privacy policy governs the use of the SOURCEMYGADGETS Services and intervention projects by our users and stakeholders, unless otherwise agreed through a written contract. We may amend this privacy policy at any time by posting a revised version on our website, or placing such notice at conspicuous points at our office facilities. The revised version will be effective 7 days after publication.',

  personal: '3. Personal Information',
  personalOptions: [
    {
      listPersonal:
        'a)	When you use the SOURCEMYGADGETS Services, we collect information sent to us by your computer, mobile phone or other electronic access device. The automatically collected information includes but is not limited to data about the pages you access, computer IP address, device ID or unique identifier, device type, geolocation information, computer and connection information, mobile network information, statistics on page views, traffic to and from the sites, referral URL, ad data, standard web log data, still and moving images.',
    },
    {
      listPersonal:
        'b)	We may also collect information you provide us including but not limited to information on web form, survey responses account update information, email address, phone number, organization you represent, official position, correspondence with the SOURCEMYGADGETS support services, and telecommunication with the SOURCEMYGADGETS.  We may also collect information about your transactions, enquiries and your activities on our platform or premises.',
    },
    {
      listPersonal:
        'c)	We may also use information provided by third parties like social media sites. Information about you provided by other sites are not controlled by the SOURCEMYGADGETS and we are, therefore, not liable for how such third parties use your information.',
    },
  ],

  whatWeDo: '4.	What we do with your personal information',
  whatWeDoIntro:
    'The purpose of collecting your personal information is to give you efficient, enjoyable and secure service. We may use your information to:',
  whatWeDoOptions: [
    {
      listWhatWeDo: 'a)	provide the SOURCEMYGADGETS Services and support;',
    },
    {
      listWhatWeDo:
        'b)	process applications and send notices about your transactions to requisite parties;',
    },
    {
      listWhatWeDo: 'c)	verify your identity;',
    },
    {
      listWhatWeDo:
        'd)	resolve disputes, collect fees, and troubleshoot problems; ',
    },
    {
      listWhatWeDo:
        'e)	manage risk, or to detect, prevent, and/or remediate fraud or other potentially prohibited or illegal activities;',
    },
    {
      listWhatWeDo:
        'f)	detect, prevent or remediate violation of laws, regulations, standards, guidelines and frameworks;',
    },
    {
      listWhatWeDo:
        'g)	improve the SOURCEMYGADGETS Services by implementing aggregate customer or user preferences;',
    },
    {
      listWhatWeDo:
        'h)	measure the performance of the SOURCEMYGADGETS Services and improve content, technology and layout;',
    },
    {
      listWhatWeDo:
        'i)	track information breach and remediate such identified breaches;',
    },
    {
      listWhatWeDo:
        'j)	manage and protect our information technology and physical infrastructure; or',
    },
    {
      listWhatWeDo:
        'k)	contact you at any time through your provided telephone number, email address or other contact details.',
    },
  ],

  cookies: '5.	Cookies',
  cookiesBody:
    'Cookies are small files placed on your computer’s hard drive that enables the website to identify your computer as you view different pages. Cookies allow websites and applications to store your preferences in order to present contents, options or functions that are specific to you. Like most interactive websites, our website uses cookies to enable the tracking of your activity for the duration of a session. Our website uses only encrypted session cookies which are erased either after a predefined timeout period or once the user logs out of the platform and closes the browser. Session cookies do not collect information from the user’s computer. They will typically store information in the form of a session identification that does not personally identify the user.',
  protect: '6.	How we protect your personal information',
  protectBody:
    'We store and process your personal information on our computers in Nigeria. Where we need to transfer your data to another country, such country must have an adequate data protection law. We will seek your consent where we need to send your data to a country without an adequate data protection law. We protect your information using physical, technical, and administrative security measures to reduce the risks of loss, misuse, unauthorized access, disclosure and alteration. Some of the safeguards we use are firewalls and data encryption, physical access controls to our data centres, and information access authorization controls.',
  share:
    '7.	How we share your information within the SOURCEMYGADGETS and with other users',
  shareIntro:
    'We may share personal information with our other corporate entities, affiliates and third parties for the following purposes:',
  shareOptions: [
    {
      listShare:
        'a)	Sale of products and services: In order to deliver your products and services purchased on our website or other platforms from third parties, we may be required to provide your personal data to such third parties.',
    },
    {
      listShare:
        'b)	Working with third party service providers: We engage third parties to perform certain functions on our behalf. Examples include fulfilling orders for products or services, delivering packages, analysing data, providing marketing assistance, processing payments, transmitting content, assessing and managing credit risk, providing customer service, among related others.',
    },
    {
      listShare:
        'c)	Mergers and acquisitions: As we continue to develop our business, we might sell or buy other businesses or services. In such transactions, customer information may be transferred together with other business assets.',
    },
    {
      listShare:
        'd)	Detecting fraud and abuse: We release account and other personal data to other companies and organizations for fraud protection and credit risk reduction, and to comply with the law.',
    },
  ],
  shareBreak: 'When we share your personal data with third parties, we:',
  shareOptions2: [
    {
      listShare2:
        'e)	require them to agree to use your data in accordance with the terms of this Privacy Policy and in accordance with the law; and',
    },
    {
      listShare2:
        'f)	only permit them to process your personal data for specified purposes and in accordance with our instructions. We do not allow our third-party service providers to use your personal data for their own purposes.',
    },
  ],
  security: '8.	Security',
  securityOptions: [
    {
      listSecurity:
        'a)	We will always hold your information securely. To prevent unauthorised access to your information, we have implemented strong controls and security safeguards at the technical and operational levels. Our website uses Secure Sockets Layer/Transport Layer Security (SSL/TLS) to ensure secure transmission of your Personal Data. You should see the padlock symbol in your URL address bar once you are successfully logged into the platform. The URL address will also start with https:// depicting a secure webpage. SSL applies encryption between two points such as your PC and the connecting server. Any data transmitted during the session will be encrypted before transmission and decrypted at the receiving end. This is to ensure that data cannot be read during transmission.',
    },
    {
      listSecurity:
        'b)	The SOURCEMYGADGETS has also taken measures to comply with global Information Security Management Systems. We have, therefore, put in place digital and physical security measures to limit or eliminate possibilities of data privacy breach incidents.',
    },
  ],
  data: '9.	Data Confidentiality Rights',
  dataBody:
    'Your information is regarded as confidential and will not be divulged to any third party, except under legal and/or regulatory conditions. You have the right to request sight of, and copies of any and all information we keep on you, if such requests are made in compliance with the Freedom of Information Act and other relevant enactments. While the SOURCEMYGADGETS is responsible for safeguarding the information entrusted to us, your role in fulfilling confidentiality duties includes, but is not limited to, adopting and enforcing appropriate security measures such as non-sharing of passwords and other platform login details, adherence with physical security protocols on our premises, dealing with only authorized officers of the company.',

  links: '10.	Links to Other Websites and Premises',
  linksOptions: [
    {
      listLinks:
        'a)	Certain transaction processing channels may require links to other websites or organisations other than ours. Please note that the SOURCEMYGADGETS is not responsible and has no control over websites outside its domain. We do not monitor or review the content of other party’s websites which are linked from our website or media platforms. ',
    },
    {
      listLinks:
        'b)	Opinions expressed or materials appearing on such websites are not necessarily shared or endorsed by us, and the SOURCEMYGADGETS should not be regarded as the publisher of such opinions or materials.  ',
    },
    {
      listLinks:
        'c)	Please be aware that we are not responsible for the privacy practices, or content of these sites. ',
    },
    {
      listLinks:
        'd)	We encourage our users to be aware of when they leave our site, and to read the privacy statements of these sites. You should evaluate the security and trustworthiness of any other site connected to this site or accessed through this site yourself, before disclosing any personal information to them. ',
    },
    {
      listLinks:
        'e)	The SOURCEMYGADGETS will not accept any responsibility for any loss or damage in whatever manner, howsoever caused, resulting from your disclosure to third parties of personal information.',
    },
  ],
  remedies: '11.	Infringements and Remedies',
  remediesBody: `Where you observe or have good grounds to believe that your privacy rights have been infringed or about to be infringed by the use of our website, please lodge your complaints or questions to: hello@sourcemygadgets.com. Our Data Protection Officer will attend to them accordingly. You can also contact us through the email for any issue in relation to your privacy protection.`,
  law: '12.	Governing Law',
  lawBody:
    'This privacy policy is made pursuant to the Nigeria Data Protection Regulation 2019 and other relevant Nigerian laws, regulations or international conventions applicable to Nigeria. Where any provision of this Policy is deemed inconsistent with a law, regulation or convention, such provision shall be subject to the overriding law, regulation or convention.',
};

export default highlight;
