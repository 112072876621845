import { useEffect, useState } from 'react';

const getQuantity = (CART_LIST_ARRAY) => {
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    const TOTAL_NUMBER_OF_ITEMS_IN_CART = CART_LIST_ARRAY.map(
      (products) => products.quantity
    ).reduce((totalSum, currentValue) => totalSum + currentValue, 0);
    setQuantity(TOTAL_NUMBER_OF_ITEMS_IN_CART);
  }, [CART_LIST_ARRAY]);

  return quantity;
};

export default getQuantity;
