import React, { useEffect, useState, useRef } from 'react';
import { BsDot } from 'react-icons/bs';

export const BLACKFRIDAY_PARAMS = {
  startDate: new Date('November 25, 2022 00:00:00').getTime(),
  endDate: new Date('December 9, 2022 23:59:59 ').getTime(),
  today: new Date().getTime(),
};

const BlackFridayClock = () => {
  const [timerDays, setTimerDays] = useState('00');
  const [timerHours, setTimerHours] = useState('00');
  const [timerMinutes, setTimerMinutes] = useState('00');
  const [timerSeconds, setTimerSeconds] = useState('00');
  const [totalTime, setTotalTime] = useState(null);

  let interval = useRef();

  const startTimer = () => {
    interval = setInterval(() => {
      const countDownDate = new Date('November 25, 2022 00:00:00').getTime();
      const now = new Date().getTime();
      const distance = countDownDate - now;

      setTotalTime(distance);
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();

    return () => {
      clearInterval(interval.current);
    };
  }, []);

  if (
    window.location.pathname.includes(
      '/blackfriday/products' && '/shop/products'
    )
  ) {
    return null;
  }
  return (
    <div
      className={`pt-[10px] ${
        totalTime <= 0 ? 'hidden invisible' : 'block'
      } mt-8 w-[150px] px-2 h-[65px] bg-primary-8 rounded-[8px]`}
    >
      <div className="flex items-center justify-center  w-max pr-2 m-auto bg-[#001C3D]  rounded-[2px]">
        <BsDot style={{ color: '#00ADEE' }} />
        <span className="text-[8px] text-gray-1 font-[500] font-Beautiste tracking-wide leading-[7.2px]">
          Black Friday Deals!
        </span>
      </div>
      <div className="flex gap-[4px] py-[6px]  items-center ">
        <div className="flex flex-col ">
          <span className="text-[14px] text-center font-[700] leading-[16px] text-[#F6FAFD] font-Karla">
            {timerDays}
          </span>
          <span className="font-Beautiste font-400 text-[8px] leading-[10px] text-[#F6FAFD]">
            Days
          </span>
        </div>
        <div className="flex items-center justify-start">
          <span className="text-primary-7 text-[14px]">:</span>
        </div>
        <div className="flex flex-col">
          <span className="text-[14px] text-center font-[700] leading-[16px] text-[#F6FAFD] font-Karla">
            {timerHours}
          </span>
          <span className="font-Beautiste font-400 text-[8px] leading-[10px] text-[#F6FAFD]">
            Hours
          </span>
        </div>
        <span className="text-primary-7 text-[14px] items-start">:</span>
        <div className="flex flex-col">
          <span className="text-[14px] font-[700] text-center leading-[16px] text-[#F6FAFD] font-Karla">
            {timerMinutes}
          </span>
          <span className="font-Beautiste text-center font-400 text-[8px] leading-[10px] text-[#F6FAFD]">
            Minutes
          </span>
        </div>
        <span className="text-primary-7 text-[14px] items-start">:</span>
        <div className="flex flex-col ">
          <span className="text-[14px] text-center font-[700] leading-[16px] text-[#F6FAFD] font-Karla">
            {timerSeconds}
          </span>
          <span className="font-Beautiste font-400 text-[8px] leading-[10px] text-[#F6FAFD]">
            Seconds
          </span>
        </div>
      </div>
    </div>
  );
};

export default BlackFridayClock;
