// This function converts any string to a URL safe slug

/**
 *
 * @param {string} productName Input product name to be converted to SLUG
 * @param {string} productId Product Id to be added into the slug
 */
const convertNameToSlug = (productName, productId = '') => {
  let slug = productName.toLowerCase();

  slug = encodeURIComponent(
    `${slug.replaceAll(' ', '_')}${productId ? `.${productId}` : ''}`
  );

  return slug;
};

export default convertNameToSlug;
