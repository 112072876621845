import { useState, useMemo, useEffect } from 'react';

import { HiViewGrid } from 'react-icons/hi';
import { FaListUl } from 'react-icons/fa';
import PageBanner from './PageBanner';
import { generateQuery, getProducts } from '../../api/airtable/helpers';
import { Header } from '../../components/common';
import { ProductList } from '../../components';
import FilterContext from '../../contexts/FilterContext';

const BlackFridayPage = () => {
  const [searchQueryText, setSearchQueryText] = useState('');

  const [showSearch, setShowSearch] = useState(false);
  const [typeFilter, setTypeFilter] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(20);
  const [offset, setOffset] = useState('');
  const [disableLoadMore, setDisableLoadMore] = useState(false);
  const [showQueryText, setShowQueryText] = useState('');
  const [gridColNo, setGridColNo] = useState(1);
  const [isScrolling, setIsScrolling] = useState(false);

  const displayWidth = window.innerWidth;

  useEffect(() => {
    let prevScrollpos = window.pageYOffset;

    const scroll = () => {
      const currentScrollPos = window.pageYOffset;

      if (currentScrollPos > prevScrollpos) {
        setTimeout(() => {
          setIsScrolling(true);
        }, 400);
      }

      if (prevScrollpos > currentScrollPos) {
        setTimeout(() => {
          setIsScrolling(false);
        }, 400);
      }

      setTimeout(() => {
        prevScrollpos = currentScrollPos;
      }, 500);
    };

    window.addEventListener('scroll', scroll);

    return () => window.removeEventListener('onscroll', scroll);
  }, []);

  // const { searchQuery, typeFilter } = useSelector((state) => state.shopFlow);

  /**
   * It takes an optional parameter of dataOffset, which is used to generate a query string, which is
   * then used to fetch products from the API
   * @returns the response from the getProducts function.
   */
  const fetchProducts = async (dataOffset = '', isBlackFriday = false) => {
    const query = generateQuery(
      {
        pageSize: 20,
        offset: dataOffset,
        filters: typeFilter
          ? {
              ...typeFilter,
            }
          : {},
        searchQuery: searchQueryText,
      },
      isBlackFriday
    );

    const res = await getProducts(query, isBlackFriday);
    return res;
  };

  /* A memoized version of the object that is passed to the FilterContext.Provider. */
  const filterProviderValue = useMemo(
    () => ({
      showSearch,
      setShowSearch,
      searchQueryText,
      setSearchQueryText,
      showQueryText,
      setShowQueryText,
      fetchProducts,
      typeFilter,
      setTypeFilter,
      products,
      setProducts,
      loading,
      setLoading,
      count,
      setCount,
      offset,
      setOffset,
      disableLoadMore,
      setDisableLoadMore,
    }),
    [
      showSearch,
      setShowSearch,
      searchQueryText,
      setSearchQueryText,
      showQueryText,
      setSearchQueryText,
      fetchProducts,
      typeFilter,
      setTypeFilter,
      products,
      setProducts,
      loading,
      setLoading,
      count,
      setCount,
      offset,
      setOffset,
      disableLoadMore,
      setDisableLoadMore,
    ]
  );

  const gridColumnHandler = (e) => {
    if (displayWidth >= 375) {
      const { col } = e.target.dataset;
      if (col === 'Grid') {
        setGridColNo(2);
      }
      if (col === 'List') {
        setGridColNo(1);
      }
    } else {
      setGridColNo(1);
    }
  };

  const activeGridState = 'bg-primary-8 text-gray-2 rounded-lg p-1';
  const inactiveGridState = 'bg-gray-2 text-primary-8 rounded-lg p-1';

  return (
    <div className="bg-gray-1">
      <FilterContext.Provider value={filterProviderValue}>
        <div>
          <Header isScrolling={isScrolling} isBlackFriday />
        </div>

        <div className={`${isScrolling ? 'pt-4' : ' w-full'}`}>
          <PageBanner />
          {displayWidth > 350 && (
            <div className="w-full flex justify-end gap-4 mt-4 p-4 sm:hidden">
              <button
                className={
                  gridColNo === 2 ? activeGridState : inactiveGridState
                }
                type="button"
              >
                <HiViewGrid
                  onClick={gridColumnHandler}
                  data-col="Grid"
                  className="text-2xl"
                />
              </button>
              <button
                className={
                  gridColNo === 1
                    ? ` ${activeGridState} p-2`
                    : inactiveGridState
                }
                type="button"
              >
                <FaListUl
                  onClick={gridColumnHandler}
                  data-col="List"
                  className="text-xl"
                />
              </button>
            </div>
          )}
          <ProductList grid={gridColNo} isBlackFriday />
        </div>
      </FilterContext.Provider>

      {/*  */}
    </div>
  );
};

export default BlackFridayPage;
