import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { FcPrevious, FcNext } from 'react-icons/fc';
import './slick.css';
import './blackfriday.css';
import photos from './Banner';
import { BLACKFRIDAY_PARAMS } from '..';

const BlackFridayBanner = () => {
  const settings = {
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <FcNext style={{ width: '7px' }} />,
    prevArrow: <FcPrevious style={{ width: '7px' }} />,
    className: 'slides',
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <Link to="/blackfriday">
      <div
        className={` ${
          BLACKFRIDAY_PARAMS.today < BLACKFRIDAY_PARAMS.endDate
            ? 'lg:w-[100%] cursor-pointer  w-[100%] md:w-[100%] mb-[20px]  black_friday lg:h-fit h-fit z-[3000] m-auto'
            : 'hidden invisible'
        } `}
      >
        <Slider {...settings}>
          {photos.map((photo) => (
            <div className="w-[100%] h-fit ">
              <img
                className="object-fill w-[100%] h-auto"
                src={photo.image}
                alt=""
              />
            </div>
          ))}
        </Slider>
      </div>
    </Link>
  );
};

export default BlackFridayBanner;
